$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 16px;

  @media (max-width: $mob) {
    margin-bottom: 12px;
  }
}

.contact_add {
  margin-bottom: 16px;

  .phone {
    max-width: unset;
  }
}

.time {
  flex: 1;
  max-width: 200px;
}

.contact,
.hours {
  display: flex;
}

.flags {
  margin-top: 16px;
  gap: 24px;
  padding-left: 12px;
  display: flex;
  align-items: center;
}

.flag {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tooltip_overlay {
  width: 260px;

  :global {
    .rc-tooltip-inner {
      text-align: center;
    }
  }
}

.contact {
  @media (min-width: #{$mob + 1}) {
    gap: 8px;
  }

  @media (max-width: $mob) {
    display: block;
  }
}

.list > li {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--thm-grey-1);
}

.add_btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  background: var(--thm-grey-1);
  border-radius: 8px;
}

.remove {
  margin-left: auto;

  button {
    width: 60px;
    height: 60px !important;
  }
}

.phone {
  flex: 1;
}

.hours {
  border-radius: 12px;
  border: 1px solid var(--thm-grey-2);

  @media (max-width: $mob) {
    margin-top: 8px;
  }

  > div {
    min-width: 140px;

    &::before {
      border: 1px solid rgba(0, 0, 0, 0);
    }

    @media (max-width: $mob) {
      width: 50%;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid var(--thm-grey-2);
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.checkboxes {
  &:empty {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.checkbox_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 8px 8px 12px;

  border-radius: 12px;
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-card);
  transition: 0.2s ease;

  &_disabled {
    opacity: 0.5;
  }
}

.checkbox_btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.checkbox_text {
  p {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.408px;
    margin-bottom: 4px;
  }

  small {
    display: block;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--thm-silver-grey);
  transition: color 0.2s ease;

  &:hover {
    color: var(--thm-black);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.select_btn {
  display: flex;
  align-items: center;
  gap: 12px;

  position: relative;
  border-radius: 8px;

  &_icon,
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &_icon {
    color: var(--clr-blue);
  }

  &_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;

    span,
    small {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    small {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: var(--thm-silver-grey);
    }
  }

  &_icon,
  button:not(.select_btn_inner) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:not(.select_btn_inner) {
    margin-left: auto;
    opacity: 0;
    pointer-events: none;
    transition: 0.15s ease;
    color: var(--thm-silver-grey);

    position: relative;
    z-index: 1;
  }

  &:hover button:not(.select_btn_inner) {
    opacity: 1;
    pointer-events: auto;
  }
}
