$tab: 770px;
$mob: 430px;

.root {
  position: relative;
  padding: 8px;
  border-radius: 12px;
  background: var(--thm-white);
}

.view_badge {
  position: absolute;
  top: 8px;
  right: 8px;

  width: 12px;
  height: 12px;

  border-radius: 50%;
  background: var(--clr-primary);
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.img {
  position: relative;
  width: 85px;
  height: 66px;
}

.img_inner {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);
  background-color: var(--thm-card);
}

.name,
.price {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin-bottom: 2px;
}

.mod {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.loan {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  margin: 12px 0;

  border-radius: 8px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);
}

.loan_row {
  display: flex;
  align-items: center;
  gap: 16px;

  p,
  b {
    flex: 1;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }

  b {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;
  }
}

.footer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.customer_name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $tab) {
    max-width: 120px;
  }
}

.date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.actions {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;

  a svg {
    width: 20px;
    height: 20px;
  }

  > a {
    width: 36px;
  }
}

.phone {
  a {
    @media (max-width: $mob) {
      width: 36px;
    }

    span {
      @media (max-width: $mob) {
        display: none;
      }
    }
  }
}
