$mob: 770px;

.root {
  flex: 1;
  padding: 16px;
}

.empty {
  height: 100%;
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  b,
  p {
    font-size: 17px;
    line-height: 23.8px;
    letter-spacing: -0.41px;
    text-align: center;

    color: var(--thm-silver-grey);
  }

  b {
    font-weight: 500;
  }

  p {
    font-weight: 400;
  }
}

.box {
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  background: var(--thm-white);
  height: 100%;

  overflow: hidden;
}

.tabs {
  position: sticky;
  top: 0;

  background-color: var(--thm-white);

  padding: 0 4px;
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid var(--thm-grey-1);

  transition: 0.2s all ease;
  z-index: 1;

  &_header {
    border-radius: 0px;
  }

  @media (max-width: $mob) {
    top: 44px;
  }
}
