$tab: 770px;

.root {
  position: relative;

  display: flex;
  align-items: center;
  gap: 8px;

  z-index: 2;

  @media (max-width: $tab) {
    padding: 0 12px;
  }
}

.search_field {
  position: relative;

  max-width: 234px;

  input {
    width: 100%;
  }
}

.dropdown.dropdown_select {
  z-index: 4;

  > ul[class] {
    padding: 4px;
  }
}

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.li {
  z-index: 7;
  position: relative;
}
