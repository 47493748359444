$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title {
  span {
    color: var(--thm-silver-grey);
  }
}

.desc {
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
  margin-bottom: 16px;

  span {
    display: block;
    margin-bottom: 8px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;
  margin-left: -4px;

  @media (max-width: $mob) {
    margin-top: -8px;
    margin-left: -8px;
  }

  > li {
    margin-top: 4px;
    margin-left: 4px;
    width: calc(25% - 4px);

    @media (max-width: $mob) {
      margin-top: 8px;
      margin-left: 8px;
      width: calc(33.33% - 8px);
    }
  }

  &_large {
    > li {
      width: calc(33.33% - 4px);

      @media (max-width: $mob) {
        width: calc(33.33% - 8px);
      }
    }
  }
}

.li {
  list-style: none;
}

.photo_cell {
  position: relative;
  width: 100%;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 74.69%;

    @media (max-width: $mob) {
      padding-top: 77%;
    }
  }

  &:hover .action_btn:not(:disabled) {
    opacity: 1;
  }
}

.photo_cell_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.24);
}

.action_btn {
  z-index: 1;
  position: absolute;
  top: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;

  transition: 0.2s ease;
  border-radius: 50%;
  color: var(--thm-black);

  background: var(--thm-grey-1);
  opacity: 0;

  &:hover:not(:disabled) {
    background: var(--clr-blue);
    color: var(--clr-white);
  }

  svg {
    @media (max-width: $mob) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: $mob) {
    opacity: 1;
    width: 24px;
    height: 24px;
  }

  &:not(:last-child) {
    left: 4px;
  }

  &:last-child {
    right: 4px;
  }
}

.btn {
  margin-top: 16px;
}
