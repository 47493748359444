.root {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--thm-black);
  transition: 0.2s ease;

  &,
  svg {
    width: 28px;
    height: 28px;
  }
}
