$desktop: 1367px;

.root {
  z-index: 1;
  position: sticky;
  top: 80px;
  width: 302px;

  @media (max-width: $desktop) {
    display: none;
  }

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
