.name {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.35px;

  display: block;
  margin-bottom: 4px;
}

.text {
  display: flex;
  gap: 4px;

  span,
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }
}
