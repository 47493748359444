$desktop-sm: 1024px;
$tab: 770px;

.root {
  display: flex;
  gap: 24px;

  @media (max-width: $tab) {
    flex-direction: column;
    gap: 12px;
  }
}

.package {
  flex: 1;
  gap: 10px;
  display: flex;
  align-items: center;

  background: var(--thm-white);
}

.package_img {
  width: 36px;
  height: 36px;
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;

  position: relative;

  b {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.41px;
  }
}

.duration {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.input {
  @media (min-width: #{$tab + 1}) {
    max-width: 120px;
  }

  input {
    min-height: 48px;
    height: 48px;
  }
}

.price_wrap {
  margin-left: auto;
  text-align: right;
}

.price {
  display: block;

  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.41px;
}

.old_price {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
  text-decoration: line-through;
}

.options_wrap {
  position: relative;

  > span svg {
    width: 20px;
    height: 20px;
    color: var(--thm-silver-grey);

    transition: 0.2s all ease;

    &:hover {
      color: var(--thm-black);
    }
  }
}

.options {
  z-index: 9;
  position: absolute;

  padding: 16px;

  border-radius: 16px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);
  box-shadow: var(--shadow-primary);

  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease;

  @media (min-width: #{$desktop-sm + 1}) {
    left: 50%;
    transform: translate(-50%, -115%);
  }

  @media (max-width: $desktop-sm) {
    left: -16px;
    bottom: 24px;
  }

  @media (max-width: $tab) {
    left: -80px;
  }

  &_open {
    opacity: 1;
    pointer-events: auto;
  }
}

.options_list > li:not(:last-child) {
  margin-bottom: 12px;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  white-space: nowrap;

  &_disabled {
    color: var(--thm-silver-grey);
  }

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}
