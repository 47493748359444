$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 16px;

  @media (max-width: $mob) {
    margin-bottom: 12px;
  }
}

.buttons {
  margin-top: 16px;
  gap: 12px;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }
}

.currency {
  margin-top: 4px;
  margin-left: 12px;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);
}

.discounts {
  margin: 16px 0;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;

  margin-bottom: 8px;
}

.text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.label {
  margin-top: 16px;
  margin-bottom: 12px;

  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.408px;
}

.types {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
}

.tip {
  display: block;
  margin-top: 4px;
  margin-left: 12px;

  color: var(--thm-silver-grey);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
}
