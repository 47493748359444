$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;

.modal {
  background-color: var(--thm-white-bg);

  :global {
    .box {
      @media (min-width: #{$tab + 1}) {
        max-width: 600px;
      }

      @media (max-width: $tab) {
        margin-top: 24px;
      }
    }
  }
}

.root {
  position: relative;
}

.wrap {
  position: relative;
}

.empty {
  min-height: calc(100vh - var(--header-h) - 108px);
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - 108px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  b {
    display: block;
    margin-bottom: 8px;

    font-size: 17px;
    font-weight: 500;
    line-height: 23.8px;
    letter-spacing: -0.41px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.41px;
    text-align: center;
  }
}

.list {
  @media (max-width: $desktop) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.list > li {
  @media (min-width: #{$desktop + 1}) {
    margin-top: -1px;
    position: relative;
  }

  &::before {
    @media (min-width: #{$desktop + 1}) {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 16px;

      width: calc(100% - 32px);
      height: 1px;
      background: var(--thm-grey-1);
    }
  }
}

.toolbar {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  @media (min-width: #{$desktop + 1}) {
    padding: 0 16px;
  }

  @media (max-width: $desktop-sm) {
    width: calc(100% + var(--main-content-padding-x) * 2);
    margin-left: calc(-1 * var(--main-content-padding-x));
    padding: 0 var(--main-content-padding-x);
    overflow-x: auto;
  }
}

.search {
  @media (min-width: #{$desktop-sm + 1}) {
    flex: 1;
    max-width: 234px;
  }

  @media (max-width: $desktop-sm) {
    min-width: 170px;
    width: 170px;
  }
}

.select {
  @media (min-width: #{$desktop-sm + 1}) {
    margin-left: auto;
  }
}

.observer {
  width: 100%;
  height: 200px;

  position: absolute;
  bottom: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;
}
