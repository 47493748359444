.root {
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  margin-top: 8px;
  gap: 17px;
  padding: 8px;

  border-radius: 8px;
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-white);
  transition: background 0.2s ease;

  &_hovered {
    background: var(--thm-card);
  }

  > li:first-child .feature::before {
    display: none;
  }
}

.feature {
  position: relative;

  gap: 4px;
  min-width: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 12px;
  line-height: 16px;

  svg {
    min-width: 16px;
    min-height: 16px;
    color: var(--thm-silver-grey);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -9px;
    transform: translateY(-50%);

    height: 100%;
    min-height: 36px;
    width: 1px;

    background: var(--thm-grey-1);
  }
}
