.root {
  background: var(--thm-grey-1);
  border-radius: 16px;

  position: relative;
  padding: 12px 16px 16px 52px;

  &_closeable {
    padding: 12px 52px 16px 52px;
  }

  p {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  svg {
    color: var(--thm-silver-grey);
  }

  > svg {
    position: absolute;
    top: 12px;
    left: 16px;

    width: 24px;
    height: 24px;
  }
}

.close_btn {
  position: absolute;
  top: 12px;
  right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: 24px;
    height: 24px;
  }
}
