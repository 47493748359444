$tab: 770px;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &_profile_open {
    z-index: -1;
    position: relative;
    pointer-events: none;
    user-select: none;
  }
}

.wrapper {
  position: relative;
  height: 100%;
}
