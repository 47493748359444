.group,
.groupList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.group {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--thm-grey-1);

  &__top {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  h3 {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}

.groupList {
  > li {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
