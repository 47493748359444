.modal_container {
  background: var(--thm-bg);
}

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px;
}
