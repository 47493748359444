$mob: 770px;
$mob-sm: 570px;

.title {
  margin-bottom: 16px;

  @media (max-width: $mob) {
    margin-bottom: 12px;
  }
}

.fields,
.emirates {
  margin-bottom: 20px;
}

.fields {
  display: flex;
  gap: 8px;

  @media (max-width: $mob) {
    flex-wrap: wrap;
  }
}

.field {
  flex: 1;

  &:first-child {
    flex: 2;

    @media (max-width: $mob) {
      width: 100%;
      flex: unset;
    }
  }
}

.plate {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  &:empty {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
