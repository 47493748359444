$desktop: 1230px;
$tab: 770px;

.root {
  @media (min-width: #{$desktop + 1}) {
    width: 1146px;
  }

  @media (max-width: $desktop) and (min-width: #{$tab + 1}) {
    width: 720px;
  }
}

.tabs {
  margin-bottom: 24px;

  li {
    flex: 1;
  }

  button {
    width: 100%;
  }
}

.preview_wrap {
  margin: 0 auto 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  width: 100%;

  &_tablet {
    max-width: 735px;

    .name {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.38px;
    }
  }

  &_phone {
    max-width: 430px;

    .name {
      margin-bottom: 16px;
    }
  }
}

.preview_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;

  border-radius: 50%;
  border: 4px solid var(--thm-white);

  overflow: hidden;
}

.avatar > div {
  width: 100% !important;
  height: 100% !important;

  > div:last-child {
    font-size: 56px !important;
  }
}
