$mob: 770px;

.list_wrap {
  @media (max-width: $mob) {
    padding-top: 12px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
  margin-left: -12px;

  @media (max-width: $mob) {
    margin-top: -16px;
    margin-left: -30px;
  }

  > li {
    margin-top: 12px;
    margin-left: 12px;

    @media (max-width: $mob) {
      margin-top: 16px;
      margin-left: 30px;
      width: calc(25% - 30px);
    }
  }
}

.list_loading {
  @media (max-width: $mob) {
    display: none;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: #{$mob + 1}) {
    display: none;
  }
}

.color_btn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $mob) {
    width: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 24px;
    height: 24px;
    color: var(--clr-white);
  }

  &_white {
    svg {
      color: var(--clr-black);
    }

    .color_circle {
      box-shadow: 0 0 0 0.5px var(--thm-silver-grey-1);

      &::after {
        border: 2px solid var(--thm-grey-2);
      }
    }
  }

  &_active .color_circle::after {
    opacity: 1;
  }
}

.color_name {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.066px;
  color: var(--thm-silver-grey);

  margin-top: 8px;

  @media (min-width: #{$mob + 1}) {
    display: none;
  }
}

.color_circle {
  display: block;
  position: relative;

  border-radius: 50%;
  background: var(--color);

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;

    display: block;
    width: calc(100% + 10px);
    height: calc(100% + 10px);

    border-radius: 50%;
    border: 2px solid var(--color);
    pointer-events: none;
    transition: 0.2s ease;
    opacity: 0;
  }

  @media (min-width: #{$mob + 1}) {
    min-width: 52px;
    width: 52px;
    height: 52px;
  }

  @media (max-width: $mob) {
    position: relative;
    width: 100%;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;

    @media (min-width: #{$mob + 1}) {
      display: none;
    }
  }
}
