.field:not(:last-child) {
  margin-bottom: 12px;
}

.counter {
  color: var(--thm-silver-grey);
  transition: color 0.2s ease;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;

  display: block;
  text-align: right;
  padding-top: 4px;
  padding-right: 12px;

  &_red {
    color: var(--clr-primary);
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
