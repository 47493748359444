$tab: 770px;

.root {
  @media (min-width: #{$tab + 1}) {
    width: 672px;
  }
}

.field {
  position: relative;
  margin-bottom: 16px;
}

.map {
  height: 500px;
  border-radius: 12px;
  overflow: hidden;
  transform: translateZ(0);

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: $tab) {
    height: 56vh;
  }

  > div > div:not(:last-child) {
    display: none;
    user-select: none;
    pointer-events: none;
    z-index: -999;
  }
}
