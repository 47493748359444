$mob: 770px;

.list_wrap {
  @media (max-width: $mob) {
    padding-top: 4px;
  }
}

.list {
  > li:not(:last-child) {
    margin-bottom: 16px;

    @media (max-width: $mob) {
      margin-bottom: 12px;
    }
  }
}
