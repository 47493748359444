$tab: 770px;

.root {
  margin-top: -24px;
}

.container {
  max-width: 1600px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabs {
  z-index: 100;
  position: sticky;
  top: var(--header-h);

  width: calc(100% + 48px);
  margin: 0 0 24px -24px;
  padding: 16px 0 0 24px;
  background: var(--thm-white);

  &::before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
    background: var(--thm-grey-1);
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search_field {
  flex: 1;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.card {
  padding: 16px;

  transition: 0.2s ease;
  border-radius: 16px;
  background: var(--thm-white);
  border: 1px solid var(--thm-grey-1);
  &:hover {
    box-shadow: var(--shadow-primary);
  }
}

.card_row {
  display: flex;
  align-items: flex-start;
}

.card_title {
  font-size: 17px;
  font-weight: 500;
  line-height: 23.8px;
  letter-spacing: -0.41px;

  margin-bottom: 2px;
}

.card_subtitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;

  color: var(--thm-silver-grey);
}

.card_actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.delete_btn button {
  width: 36px;
}

.card_hr {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background: var(--thm-grey-1);
}

.card_col {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.card_text {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
}
