$mob: 770px;

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
  margin-left: -12px;

  > li {
    margin-top: 12px;
    margin-left: 12px;
    width: calc(33.33% - 12px);

    @media (max-width: $mob) {
      width: calc(50% - 12px);
    }

    @media (max-width: 500px) {
      width: calc(100% - 12px);
    }
  }
}

.generation_btn {
  position: relative;
  width: 100%;
  overflow: hidden;

  border-radius: 12px;
  border: 1px solid var(--thm-photo-stroke);

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 60.24%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;

    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }
}

.name {
  z-index: 1;
  position: absolute;
  top: 8px;
  left: 0;

  color: var(--clr-white);
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: left;

  width: 100%;
  padding: 0 8px;
  display: flex;
  align-items: flex-start;
  gap: 4px;

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circle {
  display: block;
  min-width: 20px;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 2px solid var(--thm-silver-grey);
  background: var(--thm-white);
  transition: 0.2s ease;
}

.generation_btn:hover {
  .circle {
    border: 2px solid var(--clr-blue);
  }
}
