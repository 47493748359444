.root {
  position: relative;
  padding-bottom: 8px;

  overflow-x: auto;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: calc(100% - 12px);
    height: 1px;
    background: var(--thm-grey-1);
  }
}

.list {
  display: flex;
  gap: 4px;
}

.preview {
  position: relative;

  min-width: 80px;
  width: 80px;
  height: 80px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 8px;
  }
}

.remove_btn {
  position: absolute;
  top: 4px;
  right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: 16px;
    height: 16px;
  }
}

.time {
  position: absolute;
  bottom: 5px;
  left: 5px;
  max-width: calc(100% - 10px);

  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.066px;
  color: var(--clr-white);

  padding: 2px 6px;

  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(4px);
  border-radius: 20px;
}
