.date_group {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;

  b,
  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.38px;
  }

  span {
    color: var(--thm-silver-grey);
  }

  &:not(:first-child) {
    margin-top: 28px;
  }
}

.deposit_item {
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 8px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;

    width: 100%;
    height: 1px;
    background-color: var(--thm-grey-1);
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.amount {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;
  color: var(--thm-black);
}

.pay_method {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
  margin-bottom: 2px;
}

.pay_date {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.no_data {
  font-size: 17px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.078px;
  text-align: left;

  color: var(--thm-silver-grey);
}

.no_data,
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
