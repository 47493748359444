.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.title {
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-height: 32px;
}

.subtitle {
  max-width: 100%;
  white-space: pre-line;
}

.field_name {
  margin-bottom: 12px;
}
