.root {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 16px;

  margin-bottom: 24px;

  div:first-child {
    flex: 1;
  }

  button {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
