.group_root {
  width: 100%;
  display: flex;
  gap: 2px;
}

.button {
  flex: 1;
  box-shadow: 0 0 0 1px var(--thm-grey-1) inset;
  background-color: var(--thm-card);
  padding: 8px;
  border-radius: 4px;
  transition: 0.2s ease all;

  p {
    transition: 0.2s ease all;

    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }

  &:hover,
  &_selected {
    background-color: var(--clr-blue-15);
    box-shadow: 0 0 0 1.5px var(--clr-blue-15) inset;

    p {
      color: var(--thm-black);
    }
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
