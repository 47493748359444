.graph {
  text {
    fill: var(--thm-silver-grey);
  }

  :global {
    .apexcharts-xaxis {
      transform: translate(3px, 0);
    }
  }
}
