.root {
  border-radius: 4px;
  padding: 2px 4px;
  background-color: var(--thm-gold-10);

  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.06px;

  color: var(--thm-gold);
}
