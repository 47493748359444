$mob: 770px;

.btn {
  min-height: 36px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:disabled {
    .value_wrap {
      color: var(--thm-grey-3);
    }
  }
}

.label {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: var(--thm-silver-grey);
}

.value,
.placeholder {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
}

.placeholder {
  color: var(--clr-blue);
}

.value_wrap {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    min-width: 24px;
    color: var(--thm-grey-3);
    transition: 0.2s ease;
  }
}

.content {
  padding-top: 16px;

  @media (max-width: $mob) {
    padding-top: 8px;
  }
}

// Open
.root_open {
  .value_wrap svg {
    transform: rotate(180deg);
  }
}
