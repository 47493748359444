$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 8px;

  span {
    color: var(--thm-silver-grey);
  }
}

.subtitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
  margin-bottom: 16px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:empty {
    display: none;
  }
}
