$tab: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $tab) {
    margin-top: 8px;
  }
}

.list {
  margin-bottom: 24px;

  > li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.buttons {
  display: flex;
  gap: 12px;

  &_row {
    flex-direction: row-reverse;
  }

  &:not(.buttons_row) {
    flex-direction: column;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    &:not(.price) {
      color: var(--thm-silver-grey);
    }
  }
}

.line {
  flex: 1;
  align-self: flex-end;
  border-bottom: 1px dashed var(--thm-silver-grey);
  transform: translateY(-4px);
}

.price {
  color: var(--thm-black);
}

.policy {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  text-align: center;
  color: var(--thm-silver-grey);

  margin-top: 24px;

  a {
    color: var(--clr-blue);
  }
}
