.price {
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;

  span {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: var(--thm-silver-grey);
  }
}

.list {
  display: inline-flex;
  align-items: center;
  gap: 25px;

  padding: 8px 12px;

  border-radius: 8px;
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-card);
  opacity: 0;
  transition: 0.2s ease;

  &:not(:first-child) {
    margin-top: 30px;
  }

  > li:first-child .tariff::before {
    display: none;
  }

  &_visible {
    opacity: 1;
  }
}

.tariff {
  position: relative;

  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.41px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    color: var(--thm-silver-grey);

    display: block;
    margin-top: 4px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -13px;
    transform: translateY(-50%);

    height: 100%;
    min-height: 36px;
    width: 1px;

    background: var(--thm-grey-1);
  }
}
