.hr {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: var(--thm-grey-1);
}

.tip {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
  padding-left: 12px;
  padding-top: 8px;
}

.btn {
  margin-top: 24px;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.32px;

  display: block;
  margin-bottom: 12px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.textarea {
  position: relative;
  margin-bottom: 24px;

  small {
    display: block;
    text-align: right;
    margin: 4px 12px 0 auto;

    font-size: 12px;
    line-height: 16px;
    color: var(--thm-silver-grey);
  }
}

.emails {
  li {
    display: flex;
    gap: 8px;

    button {
      width: 56px;
      height: 56px;
    }
  }

  > li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.add_email {
  margin-top: 12px;
}

// Range
.range {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 40px;

  &_track {
    display: flex;
    height: 20px;
    width: 100%;

    &_inner {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      align-self: center;
    }
  }

  &_thumb {
    border-radius: 50%;
    background-color: var(--clr-blue);

    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    outline: 0;

    &_inner {
      position: absolute;
      bottom: -27px;

      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.41px;
      white-space: nowrap;
    }
  }
}

.switch {
  position: relative;

  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  > div {
    pointer-events: none;
    user-select: none;
  }

  &_text {
    display: flex;
    flex-direction: column;
    gap: 4px;

    b {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.4px;
      text-align: left;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.24px;
      text-align: left;

      color: var(--thm-silver-grey);
    }
  }
}
