$mob: 770px;

.list {
  @media (min-width: #{$mob + 1}) {
    display: flex;
    flex-wrap: wrap;
    margin-top: -8px;
    margin-left: -12px;
  }

  > li {
    @media (min-width: #{$mob + 1}) {
      margin-top: 8px;
      margin-left: 12px;
      width: calc(25% - 12px);
    }

    @media (max-width: $mob) {
      border-bottom: 1px solid var(--thm-grey-1);
    }
  }
}

.toggler {
  .year_btn {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--thm-silver-grey);
  }

  svg {
    transition: transform 0.2s ease;
  }
}

.toggler_opened svg {
  transform: rotate(180deg);
}

.year_btn {
  width: 100%;
  padding: 8px 12px;

  text-align: left;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border-radius: 8px;
  transition: 0.2s ease;

  &:hover {
    background: var(--clr-blue-15);
    color: var(--clr-blue);
  }

  @media (max-width: $mob) {
    padding: 16px 0;
  }
}
