$tab: 770px;

.root {
  @media (min-width: #{$tab + 1}) {
    width: 432px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;

  & + .package_icon,
  & + .close_btn {
    margin-left: auto;
  }
}

.package_icon {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  @media (max-width: $tab) {
    display: none;
  }
}

.duration {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  margin-top: 12px;

  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.options {
  width: 100%;

  > li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.option {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;

  &_included {
    color: var(--thm-black);
  }

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: 24px;

  > div {
    width: 42%;
  }

  > button {
    flex: 1;
  }
}

.balance_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  text-align: center;
}

.balance_wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--thm-silver-grey);
}

.balance {
  font-size: 48px;
  font-weight: 500;
  line-height: 57px;
}

.plus_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action_wrap {
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--thm-grey-1);
  }

  p {
    color: var(--clr-primary);
  }

  button,
  p {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}

.update_btn {
  display: flex;
  align-items: center;
  gap: 4px;

  color: var(--clr-blue);

  svg {
    width: 20px;
    height: 20px;
  }
}

.autoext {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  margin-top: 24px;

  > button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.autoext_text {
  p {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.408px;
  }

  small {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }
}

.hidden_backdrop {
  opacity: 0 !important;
}
