$desktop-lg: 1630px;
$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;

.root {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 24px;

  @media (min-width: #{$desktop-sm + 1}) and (max-width: $desktop) {
    width: calc(100% + 16px);
    margin-left: -8px;
  }

  @media (min-width: #{$desktop + 1}) {
    padding: 0 16px;
  }

  @media (max-width: $desktop-sm) {
    width: calc(100% + var(--main-content-padding-x) * 2);
    margin-left: calc(-1 * var(--main-content-padding-x));
    padding: 0 var(--main-content-padding-x);
    overflow-x: auto;

    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.list {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: #{$desktop-sm + 1}) {
    flex-wrap: wrap;
  }
}

.li,
.li_input {
  z-index: 7;
  position: relative;
}

.reset_btn {
  margin-left: 8px;
  position: relative;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--clr-blue);

  &::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);

    width: 1px;
    height: 16px;
    background: var(--thm-grey-1);
  }
}

.dropdown.dropdown_imp {
  z-index: 4;

  > ul[class] {
    border-radius: 16px;

    padding: 16px;
    display: flex;
    overflow: unset;

    @media (min-width: #{$tab + 1}) {
      max-height: 492px;
    }

    > li {
      max-height: 100%;
      overflow-y: auto;

      @media (max-width: $tab) {
        min-width: 200px;
      }

      &:not(:last-child) {
        padding-right: 24px;
        margin-right: 24px;
        border-right: 1px solid var(--thm-grey-1);
      }
    }
  }

  &.dropdown_range > ul[class] > li {
    overflow: unset;
  }
}

.dropdown.dropdown_select {
  z-index: 4;

  > ul[class] {
    padding: 4px;
  }
}

.generations {
  min-width: 723px;
  width: 723px;

  @media (max-width: $desktop-lg) {
    min-width: 362px;
    width: 362px;
  }

  @media (max-width: $desktop) {
    min-width: 200px;
    width: 200px;
  }
}

.select {
  margin-left: auto;
}

.search {
  min-width: 170px;
  width: 170px;
}
