$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;

.root {
  min-height: calc(100vh - var(--header-h));
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h));
  padding: 36px 0;

  @media (max-width: $desktop) {
    padding: 24px 0;
  }

  @media (max-width: $desktop-sm) {
    padding: 0;
    background: var(--thm-bg);
    border-top: 1px solid var(--thm-grey-1);
  }

  :global {
    .box-first {
      @media (max-width: $desktop-sm) {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }

    .box {
      border-radius: 24px;

      @media (max-width: $desktop-sm) {
        border: 0;
        box-shadow: unset;
      }

      @media (max-width: $tab) {
        border-radius: 16px;
      }

      h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0.36px;

        @media (max-width: $tab) {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.35px;
        }
      }
    }
  }
}

.container {
  position: relative;
  width: 100%;
  padding-bottom: 81px; // sticky bar height

  @media (max-width: $desktop-sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.inner {
  display: flex;
  align-items: flex-start;
  gap: 36px;

  @media (max-width: $desktop) {
    gap: 24px;
  }
}

.sections_wrap {
  flex: 1;
}

.sections {
  width: 100%;

  &:not(.sections_center) {
    @media (min-width: #{$desktop + 1}) {
      max-width: 724px;
    }
  }

  &_center {
    margin: 0 auto;
    @media (min-width: #{$desktop-sm + 1}) {
      max-width: 724px;
    }
  }
}
