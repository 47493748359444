.root {
  position: relative;
}

.root_minimized {
  .btn {
    .row {
      min-width: 36px;
      width: 36px;
    }

    .text {
      display: none;
    }
  }
}

.btn,
.row {
  gap: 8px;
  display: flex;
  align-items: center;
}

.row {
  max-width: calc(100% - 32px);
  width: calc(100% - 32px);
}

.btn {
  width: 100%;
  padding: 12px;
  justify-content: space-between;

  transition: 0.2s ease;
  border-bottom: 1px solid var(--thm-grey-1);

  .name_wrap svg {
    display: block;
  }

  &_open {
    background: var(--thm-grey-1);

    .name_wrap svg {
      transform: rotate(180deg);
    }
  }
}

.text {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: calc(100% - 16px);
}

.name_wrap {
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;

  svg {
    display: none;
    min-width: 16px;
    width: 16px;
    height: 16px;
    transform-origin: 50% 50%;
    transition: transform 0.2s ease;
  }
}

.name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
}

.email {
  display: block;

  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.name,
.email {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  width: 218px !important;
  &:not(.dropdown_sidebar_modal) {
    left: 8px !important;
  }
}

.dropdown_sidebar_modal {
  left: unset !important;
  right: 40px !important;
  top: 69px !important;
}

.dropdown_btn_wrap {
  position: relative;
}

.dropdown_btn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  border-radius: 8px;
  background: var(--thm-white);
  transition: 0.2s ease;

  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: left;

  color: var(--thm-black);

  span:not(.chevron_right) {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);
  }

  span:first-of-type {
    margin-left: auto;
  }

  &:hover {
    background: var(--thm-grey-1);
  }

  > svg {
    width: 20px;
    height: 20px;
    margin: 6px;
  }

  &_red {
    color: var(--clr-primary);
  }
}

.dropdown_item {
  position: relative;
}

.submenu_anchor {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  padding: 0;
}

.chevron_right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  color: var(--thm-silver-grey);

  > svg {
    width: 10px;
    height: 28px;
  }
}

.lang_dropdown {
  transform: translateX(100%);
  top: calc(100% + 74px) !important;
}

.theme_dropdown {
  transform: translateX(100%);
  top: calc(100% + 24px) !important;
}

.theme_btn,
.lang_btn {
  gap: 8px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  &,
  span {
    display: flex;
    align-items: center;
  }

  span {
    justify-content: center;
  }

  svg {
    width: 20px;
    height: 20px;
    color: var(--clr-blue);
  }
}

.dropdown_user {
  padding: 8px;
}

.dropdown_hr {
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background: var(--thm-grey-1);
}
