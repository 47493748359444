.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 24px;

  border-radius: 24px;
  background: var(--thm-grey-1);

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  ul {
    > li {
      position: relative;
      padding-left: 22px;

      &::before {
        content: '';
        position: absolute;
        top: 0.6em;
        left: 10px;

        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: var(--thm-black);
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.title {
  display: block;

  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;
}

.root_red {
  background: var(--clr-red-15);

  .title {
    color: var(--clr-primary);
  }
}
