.root {
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 8px;
}

.open_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;

  border-radius: 12px;
  background: var(--thm-grey-1);

  svg {
    width: 16px;
    height: 16px;
  }
}

.wrap {
  position: relative;
}

.dropdown_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  white-space: nowrap;

  svg {
    width: 20px;
    height: 20px;
  }

  &_red {
    color: var(--clr-primary);
  }
}
