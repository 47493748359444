.li {
  margin-top: -1px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background: var(--thm-grey-1);
  }
}
