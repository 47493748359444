.root {
  background: var(--clr-blue-15);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--clr-blue);

  transition: 0.2s ease all;

  &:hover {
    &,
    svg,
    span {
      opacity: 0.84;
    }
  }
}
