.root {
  height: 250px;
  width: 100%;

  margin-top: 12px;

  background-size: 1100px 250px;
}

.blur {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(10px);

  h3 {
    padding: 16px 32px;

    border-radius: 12px;
    border: 1px solid var(--thm-grey-2);
    background-color: var(--thm-bg-90);
  }
}
