.stepper {
  width: 100%;
  display: flex;
  align-items: center;
}

.step_wrap {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    flex: 1;
  }

  span {
    display: flex;
    flex: 1;
    height: 3px;
    background: var(--thm-grey-2);
  }
}

.step {
  width: 16px;
  height: 16px;
  border: 2px solid var(--thm-white-bg);
  border-radius: 50%;
  background: var(--thm-grey-2);
}

.step_completed {
  &,
  & + span {
    background-color: var(--clr-green);
  }
}

.step_active {
  &,
  & + span {
    background-color: var(--thm-black);
  }
}
