.root {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  height: 48px;

  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.41px;

  transform-origin: 50% 50%;
  transition: 0.2s ease;
  border-radius: 27px;
  border: 1px solid rgba(0, 0, 0, 0);

  img {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &:not(.root_outlined) {
    &:not(.root_active) {
      background: var(--thm-grey-1);
    }

    &.root_active {
      border: 1px solid var(--clr-blue);
    }

    &.root_active,
    &:hover:not(:disabled) {
      background: var(--clr-blue-15);
      color: var(--clr-blue);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.root_outlined {
  padding: 16px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);

  &.root_active {
    border: 1px solid var(--clr-blue);
    background: var(--clr-blue-15);
    color: var(--clr-blue);
  }

  &:hover:not(:disabled) {
    color: var(--clr-blue);
  }
}
