.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.singlefield {
  position: relative;
}

.save_btn {
  position: absolute;
  top: -1px;
  right: 0;

  width: 48px;
  height: calc(100% + 2px);
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--clr-white);
  background: var(--clr-blue);
  border-radius: 0px 12px 12px 0px;
  transition: 0.2s ease;

  &:not(.save_btn_visible) {
    opacity: 0;
    pointer-events: none;
  }
}

.modal_container.modal_container_imp {
  max-width: 454px;
  width: 100%;
}
