$tab: 770px;

.modal_container.modal_container_imp {
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh);
  max-height: calc(var(--vh, 1vh) * 100);
  padding: 0;

  border: 0;
  background: 0;
  border-radius: 0;
  box-shadow: unset;
  pointer-events: none !important;
}

.modal_backdrop {
  background: rgba(#000, 0.85);
}

.root {
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 72px;

  @media (max-width: $tab) {
    padding-top: 68px;
  }
}

.header {
  width: 100%;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media (max-width: $tab) {
    padding: 12px 8px;
  }
}

.info {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;

  &:empty {
    display: none;
  }

  &:first-child {
    margin-right: 8px;
  }

  a,
  button {
    width: 44px;
    height: 44px;

    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    color: var(--clr-white);
    transition: 0.2s ease;
    opacity: 0.75;

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);

      display: block;
      width: 100%;
      height: 100%;

      background: rgba(#fff, 0.03);
      border-radius: 50%;
      transition: 0.2s ease;
      transform-origin: 50% 50%;
    }

    &:hover {
      opacity: 1;

      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

.content {
  width: 100%;
  height: calc(100% - 72px);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $tab) {
    height: 100%;
  }

  .img,
  video {
    display: block;
    max-width: calc(100% - 80px);
    max-height: 100%;
    object-fit: contain;
  }
}

.header,
.arrow_btn,
.content .img,
.content video {
  pointer-events: auto;
}

.arrow_btn {
  position: absolute;
  bottom: 0;

  height: calc(100% - 72px);
  width: 40px;

  color: var(--clr-white);

  svg {
    width: 24px;
    height: 24px;
  }

  &_prev {
    left: 0;

    svg {
      transform: translateY(-36px) scale(-1);
    }
  }

  &_next {
    right: 0;

    svg {
      transform: translateY(-36px);
    }
  }
}
