$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;

.root {
  z-index: 1;
  position: sticky;
  top: 80px;
  width: 302px;

  @media (max-width: $desktop) {
    width: 229px;
  }

  @media (max-width: $desktop-sm) {
    display: none;
  }
}

.list {
  border-bottom: 1px solid var(--thm-grey-1);
  padding-bottom: 16px;
  margin-bottom: 16px;

  > li:not(:last-child) {
    margin-bottom: 4px;
  }
}

.text {
  svg {
    min-width: 24px;
  }

  &:disabled {
    color: var(--thm-silver-grey);
  }

  span {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.408px;

    word-break: break-word;
    text-align: left;
  }

  &_checked {
    color: var(--clr-green);
  }
}

.text,
.reset_btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;

  transition: 0.2s ease;
  border-radius: 12px;

  &:hover {
    background: var(--thm-grey-1);
  }
}

.reset_btn {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.408px;
  color: var(--clr-primary);
  margin-bottom: 16px;
}
