.root {
  display: flex;
  align-items: center;
  gap: 2px;

  color: var(--thm-black);
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;

  svg {
    min-width: 22px;
    width: 22px;
    height: 22px;
  }
}
