$tab: 770px;

.wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;

  background: rgba(0, 0, 0, 0.16);

  transition: 0.2s ease;
}

.close_btn {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.hidden {
  display: none;
}

.root {
  position: absolute;
  bottom: -100%;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 573px;

  border-radius: 24px 24px 0 0;
  background-color: var(--thm-card);

  overflow: hidden;
  opacity: 0;

  transition: 0.2s ease;
}

.open {
  opacity: 1;
}

.root_open {
  bottom: 0;
  opacity: 1;
}

.header {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 16px;

  padding: 24px 24px 16px 24px;

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;

    color: var(--thm-black);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 12px 16px;
  min-height: 500px;
  overflow-y: auto;
}

.messages {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;

  flex: 1;
  width: 100%;
}

.message {
  position: relative;
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  border-radius: 17px;
  background: var(--clr-blue);

  a {
    text-decoration: underline;
    color: var(--clr-white);
  }

  p {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.408px;

    color: var(--clr-white);
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.reply {
  position: relative;
}

.reply_btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.tail {
  position: absolute;
  bottom: -2px;
  right: 100%;
  left: 100%;
  transform: translateX(-50%);

  display: block;
  width: 13px;

  color: var(--clr-blue);
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  p {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.408px;
    text-align: center;

    color: var(--thm-black);
  }

  svg {
    color: var(--clr-blue);
  }
}

.dropdown_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  color: var(--clr-primary);

  @media (min-width: #{$tab + 1}) {
    min-width: 224px;
  }

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}
