.root {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;

  z-index: 3;
  position: absolute;

  &_top {
    transform: translateY(-100%);
  }

  &_open {
    opacity: 1;
    pointer-events: auto;
  }
}

.list {
  min-width: 200px;
  padding: 8px;

  background: var(--thm-white);
  box-shadow: var(--shadow-primary);
  border-radius: 12px;
}

.action_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  width: 100%;
  padding: 12px;

  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  text-align: left;
  white-space: nowrap;
  user-select: none;

  border-radius: 8px;
  transition: 0.2s ease;

  &:hover {
    background: var(--thm-grey-1);
  }

  &_delete {
    color: var(--clr-primary);
  }

  svg {
    width: 22px;
    height: 22px;
  }
}
