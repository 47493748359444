.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4px;

  color: var(--thm-black);
}

.preview {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-start;
}

.message {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: 320px;

  padding: 6px 8px 6px 12px;
  border-radius: 17px;
  background-color: var(--thm-light-gold);

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;

    color: var(--thm-gold);
  }

  small {
    align-self: flex-end;

    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    line-height: 13.13px;

    color: #d7be38;
  }
}

.tail {
  position: absolute;
  bottom: -2px;
  right: 100%;
  transform: translateX(50%);

  color: var(--thm-light-gold);

  display: block;
  width: 13px;
}
