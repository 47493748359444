$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.field:not(:last-child) {
  margin-bottom: 12px;
}

.btn {
  margin-top: 24px;
}

// Address list
.list {
  > li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.address {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;

  border-radius: 12px;
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-card);

  transition: border-color 0.2s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 12px;
    border: 2px solid var(--clr-blue);
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
  }
}

.address_active {
  border: 1px solid rgba(0, 0, 0, 0);

  &::before {
    opacity: 1;
  }
}

.address_title {
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.408px;
  margin-bottom: 2px;
}

.address_subtitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.address_info {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.address_btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.empty {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}
