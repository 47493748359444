$tab: 770px;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  text-align: center;
}

.avatar {
  margin-bottom: 12px;
}

.name {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.35px;

  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-height: 64px;
  margin-bottom: 4px;

  @media (max-width: $tab) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
  }
}

.user_info p,
.user_info span,
.desc {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  white-space: pre-wrap;
  word-break: break-word;
  color: var(--thm-silver-grey);

  @media (max-width: $tab) {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
  }
}

.user_info {
  display: flex;
  flex-direction: row;
  gap: 4px;

  margin-bottom: 12px;

  span {
    color: var(--thm-silver-grey-1);
  }
}

.status_online {
  color: var(--clr-green) !important;
}
