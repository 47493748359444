$desktop: 1300px;
$tab: 770px;

.root {
  position: relative;
  padding: 16px;

  @media (max-width: $desktop) {
    padding: 0;
  }
}

.empty {
  position: relative;
  max-width: 360px;
  margin: 0 auto;

  @media (max-width: $tab) {
    background-color: var(--thm-card);
    padding: 12px 16px;
    border-radius: 16px;
  }
}

.empty_dropdown {
  transform: translateY(calc(-100% - 76px));
}

.empty_text {
  text-align: center;
}

.observer {
  opacity: 0;
  pointer-events: none;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 400px;
}
