.root {
  padding: 8px;

  border-radius: 16px;
  background: var(--thm-white);
  overflow: hidden;
}

.img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 2px;
}

.img_first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.img_last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.img_one {
  border-radius: 8px;
}

.img_filler {
  min-width: 8px;
  width: 8px;
  visibility: hidden;
  pointer-events: none;
}

.images_wrap {
  position: relative;
}

.images {
  display: flex;
  gap: 2px;
  width: calc(100% + 16px);
  margin-left: -8px;
  padding-left: 8px;
  overflow-x: auto;

  > li {
    position: relative;
    display: flex;
  }
}

.reel_icon {
  z-index: 1;
  position: absolute;
  top: 4px;
  left: 172px;

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  backdrop-filter: blur(20px);
  background-color: #1e1e1ebf;

  svg {
    width: 16px;
    height: 16px;
    color: var(--clr-white);
  }
}

.status {
  z-index: 1;
  position: absolute;
  top: 4px;
  left: 4px;

  padding: 4px 6px;

  background: var(--clr-primary);
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--clr-white);

  &_green {
    background: var(--clr-green);
  }

  &_orange {
    background: var(--clr-orange);
  }
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.info {
  position: relative;
  margin: 8px 0;
}

.price {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;

  display: block;
  margin-bottom: 4px;
}

.name {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.list {
  display: flex;
  gap: 4px;
}

.ad_effectiveness {
  > div > div {
    min-height: 26px;
    padding: 4px 8px;
    border-radius: 12px;
  }
}

.stats_cell {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 8px;

  border-radius: 12px;
  background: var(--thm-grey-1);
  border: 1px solid var(--thm-silver-grey-2);

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}

.stats_icon {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
}

.send_offer {
  position: relative;
  padding-left: 7px;

  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--clr-blue);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    width: 1px;
    height: 12px;
    background: var(--thm-silver-grey-2);
  }

  svg {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
}

.footer {
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 64px;
    height: 36px;

    z-index: 2;
    position: absolute;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &_dark {
    &::before,
    &::after {
      background: linear-gradient(90deg, rgba(30, 30, 30, 0) 0%, #1e1e1e 100%);
    }
  }

  &::before {
    left: -9px;
    transform: scaleX(-1);
  }

  &::after {
    right: 66px;
  }

  &:not(.footer_scroll_start) {
    &::before {
      opacity: 1;
    }

    &::after {
      opacity: 0;
    }
  }

  &.footer_scroll_start {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}

.promotions {
  width: calc(100% - 58px);
  margin-left: -8px;
  padding-left: 8px;
  overflow-x: auto;
  padding-top: 8px;

  position: relative;
  z-index: 1;

  &:not(.empty) {
    min-height: 44px;
  }
}

.actions {
  z-index: 3;
  position: absolute;
  bottom: 1px;
  right: 0;

  > ul {
    display: flex;
    background: var(--thm-grey-1);

    border: 0;
    box-shadow: 0 0 0 1px var(--thm-silver-grey-2);

    > li {
      border-bottom: 0 !important;
      position: relative;
      &:not(:first-child)::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 20px;
        background: var(--thm-silver-grey-2);
      }

      > button {
        min-width: 36px;
        width: 36px;
        height: 34px;

        > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
