.root {
  height: 100%;
  padding: 36px 24px;
  gap: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
  text-align: center;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.message {
  padding: 16px;
  max-width: 250px;
  margin: 0 auto;

  border-radius: 8px;
  background: var(--thm-photo-stroke);
  backdrop-filter: blur(48px);

  text-align: center;

  b,
  p {
    word-break: break-word;
  }

  b {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.08px;

    display: block;
    margin-bottom: 8px;
  }

  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
}

.field {
  width: 100%;
  margin-top: 12px;
}

.check_label {
  padding: 4px 16px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.check_list {
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-card);
  border-radius: 8px;

  overflow: hidden;
}

.check_btn {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;

  padding: 6px 12px;
  min-height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: var(--clr-primary);
  }
}
