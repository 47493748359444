.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.list {
  margin-bottom: 16px;
  > li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.expense_row {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.expense_color {
  min-width: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.expense_count {
  margin-left: auto;
}

.graph {
  margin-bottom: 16px;

  text {
    fill: var(--thm-silver-grey);
  }
}
