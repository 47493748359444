$tab: 770px;
$header-height: 61px;
$header-padding: 24px;
$toolbar-height-with-mt: 60px;

.wrap {
  position: relative;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: calc(
    100vh - $header-height - $header-padding - $toolbar-height-with-mt -
      $header-padding
  );

  > div {
    margin-bottom: 28px;
  }

  > p {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
    text-align: center;
  }
}

.toolbar {
  padding: 0 16px;
  justify-content: space-between;
  margin-bottom: 24px;

  &,
  &_row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media (max-width: $tab) {
    padding: 0;
    overflow-x: auto;
  }
}

.search_field {
  flex: 1;
  max-width: 234px;
  margin-right: auto;

  @media (max-width: $tab) {
    flex: 1;
    max-width: unset;
    min-width: 234px;
    margin-right: unset;
  }
}

.saveBtn {
  margin-top: 24px;
}

.observer {
  width: 100%;
  height: 200px;

  position: absolute;
  bottom: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;
}
