$tab: 770px;

.list {
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: $tab) {
    gap: 8px;
  }

  > li {
    flex: 1;
  }
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  width: 100%;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: center;

  border-radius: 12px;
  background: var(--thm-white);
  color: var(--clr-blue);

  @media (max-width: $tab) {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.066px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;

    @media (max-width: $tab) {
      width: 24px;
      height: 24px;
    }
  }

  &_remove {
    color: var(--clr-primary);
  }
}

.li_more {
  position: relative;
}
