.button {
  position: relative;

  font-weight: 500;
  font-size: 17px;
  letter-spacing: -0.408px;
  line-height: 1;
  white-space: nowrap;
  text-align: center;

  transition: 0.2s ease;
  border-radius: 12px;
}

.button,
.inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  transition: 0.15s ease;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  pointer-events: none;
  transition: 0.15s ease;
}

.button_loading {
  &:disabled {
    pointer-events: none;
  }

  .inner {
    opacity: 0;
    pointer-events: none;
  }

  .spinner {
    opacity: 1;
  }
}

// Sizes
.button_fullwidth {
  width: 100%;
}

.button_size_s,
.button_size_compact {
  font-weight: 400;
}

.button_size_s,
.button_size_m {
  &:not(.button_link) {
    padding: 12px;
    height: 48px;
  }
}

.button_size_l {
  &:not(.button_link) {
    padding: 16px;
    height: 56px;
  }
}

.button_size_compact {
  border-radius: 8px;

  font-size: 15px;
  letter-spacing: -0.24px;

  &:not(.button_link) {
    padding: 8px 12px;
    height: 36px;
  }
}

// Colors
.button_black {
  --btn-primary: var(--thm-black);
  --btn-secondary: var(--thm-grey-1);

  &.button_primary {
    color: var(--thm-white);
  }
}

.button_green {
  --btn-primary: var(--clr-green);
  --btn-secondary: var(--clr-green-15);
}

.button_red {
  --btn-primary: var(--clr-primary);
  --btn-secondary: var(--clr-red-15);
}

.button_blue {
  --btn-primary: var(--clr-blue);
  --btn-secondary: var(--clr-blue-15);
}

// Variants
.button_primary {
  background: var(--btn-primary);
  color: var(--clr-white);
}

.button_secondary {
  background: var(--btn-secondary);
  color: var(--btn-primary);
}

.button_primary,
.button_secondary {
  &:hover:not(:disabled) {
    opacity: 0.84;
  }

  &:disabled:not(.button_loading) {
    background: var(--thm-grey-1);
    color: var(--thm-grey-3);
  }
}

.button_tertiary {
  color: var(--btn-primary);

  &:hover:not(:disabled) {
    background: var(--thm-grey-1);
  }

  &:disabled:not(.button_loading) {
    color: var(--thm-grey-3);
  }
}

.button_outline {
  border: 1px solid var(--thm-grey-1);
  color: var(--btn-primary);
  background: var(--thm-card);

  &:hover:not(:disabled) {
    background: var(--thm-grey-1);
  }

  &:disabled:not(.button_loading) {
    color: var(--thm-grey-3);
  }
}

.button_link {
  color: var(--btn-primary);

  &:hover:not(:disabled) {
    opacity: 0.84;
  }

  &:disabled:not(.button_loading) {
    color: var(--thm-grey-3);
  }
}
