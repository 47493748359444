$trs-d: 0.2s;
$trs-ease: ease-in-out;

.root {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 50%;
  transition: opacity $trs-d $trs-ease;
}

.root[data-open='true'] {
  opacity: 1;
  pointer-events: auto;

  .container {
    pointer-events: auto;
    transform: scale(1);
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.5);

  span {
    display: none;
  }
}

.container {
  background: var(--thm-white);
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  border: 1px solid var(--thm-grey-1);

  pointer-events: none;
  transform: scale(0.95);
  transform-origin: 50% 50%;
  transition: transform $trs-d $trs-ease;

  position: relative;
  z-index: 1;

  padding: 24px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  overflow-y: auto;
}
