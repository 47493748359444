.container {
  display: inline-block;
}

.input {
  display: none;
}

.label {
  position: relative;

  display: block;
  margin: 0 auto;
  width: 51px;
  height: 31px;

  border-radius: 16px;
  background: var(--thm-silver-grey-1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.circle {
  position: absolute;
  top: 2px;
  left: 2px;

  display: block;
  height: 27px;
  width: 27px;

  border-radius: 50%;
  transition: all 0.2s ease;
  background: var(--clr-white);
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}

.container_checked {
  .label {
    background: var(--clr-blue);
  }

  &.container_primary .label {
    background: var(--clr-primary);
  }

  &.container_black .label {
    background: var(--thm-black);
  }

  &.container_green .label {
    background: var(--clr-green);
  }

  .circle {
    transform: translateX(20px);
  }
}
