.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 16px;
  gap: 12px;

  background: var(--thm-white);

  border-bottom: 1px solid var(--thm-grey-1);
}

.field {
  flex: 1;

  input {
    width: 100%;
  }
}

.close_btn {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: var(--clr-primary);
}
