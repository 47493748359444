$desktop-sm: 1024px;

.root {
  display: flex;
  align-items: flex-start;
  gap: 24px;

  @media (max-width: $desktop-sm) {
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 16px;
  }
}

.content {
  flex: 1;
}

.message:not(:empty) {
  margin-bottom: 16px;
}
