.root {
  padding: 12px;

  border-radius: 12px;
  background: var(--thm-white);
  border: 1px solid var(--thm-grey-1);
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.text {
  flex: 1;
}

.title {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);
}

.hr {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background: var(--thm-photo-stroke);
}
