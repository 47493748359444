.field {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.on_validation,
.valid {
  padding-left: 12px;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.valid {
  color: var(--clr-green);
}

.on_validation {
  color: var(--clr-blue);

  display: flex;
  align-items: center;
  gap: 4px;
}

.instructions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 16px;
}

.text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  a {
    color: var(--clr-blue);
  }
}

.login_input {
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 24px;
}

.title {
  margin-bottom: 12px !important;
}
