.root {
  max-width: 676px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 24px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  padding-top: 12px;
}

.msg {
  margin-bottom: 12px;

  > div {
    padding: 12px;
    gap: 8px;
    display: flex;
    align-items: center;

    svg {
      position: static;
    }
  }
}
