$desktop: 1300px;
$desktop-sm: 1024px;

.root {
  > li {
    @media (max-width: $desktop-sm) {
      margin-bottom: 8px;
    }

    @media (min-width: #{$desktop-sm + 1}) {
      margin-top: -1px;
      margin-bottom: 16px;
      padding-bottom: 16px;
      position: relative;
    }

    @media (min-width: #{$desktop-sm + 1}) and (max-width: $desktop) {
      margin-bottom: 12px;
      padding-bottom: 12px;
    }

    &::before {
      @media (min-width: #{$desktop-sm + 1}) {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--thm-grey-1);
      }
    }
  }
}

.root + .root {
  margin-top: 16px;

  @media (max-width: $desktop) {
    margin-top: 12px;
  }

  @media (max-width: $desktop-sm) {
    margin-top: 8px;
  }
}
