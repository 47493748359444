.root {
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.root_simple) {
    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: -16px;
      left: -16px;
      width: calc(100% + 32px);
      height: calc(100% + 32px);

      background: var(--thm-white);
      box-shadow: var(--shadow-primary);
      border: 1px solid var(--thm-grey-1);
      border-radius: 16px;
      opacity: 0;
      transition: 0.2s ease;
      pointer-events: none;
    }

    &:hover {
      z-index: 6;

      &::before {
        opacity: 1;
      }

      .link {
        pointer-events: auto;
      }

      .name {
        color: var(--clr-primary);
      }

      .col {
        svg {
          opacity: 1;
        }
      }

      .img,
      .col {
        background: var(--thm-card);
      }
    }
  }
}

.root_simple {
  .advert {
    gap: 16px;
  }

  .img {
    min-width: 151px;
    width: 151px;
  }

  .tariffs {
    margin-top: 12px;
  }
}

.advert {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
}

.img {
  position: relative;
  overflow: hidden;
  min-width: 191px;
  width: 191px;

  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);
  background: var(--thm-white);
  transition: background 0.2s ease;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;
  }

  > span {
    display: block;
    height: 0;
  }
}

.img_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  flex: 1;
  min-width: 260px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.name_wrap {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  position: relative;
}

.name {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4px;
  transition: 0.2s ease;
}

.specs {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;

  span {
    color: var(--thm-silver-grey);
  }
}

.tariffs {
  margin-top: 8px;
}

.dates {
  padding-top: 12px;
  margin-top: auto;

  color: var(--thm-silver-grey);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
}
