.rc-tooltip.rc-tooltip-anim-appear,
.rc-tooltip.rc-tooltip-anim-enter {
  opacity: 0;
}

.rc-tooltip.rc-tooltip-anim-enter,
.rc-tooltip.rc-tooltip-anim-leave {
  display: block;
}

.rc-tooltip-anim-enter {
  opacity: 0;
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  animation-play-state: paused;
}

.rc-tooltip-anim-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-play-state: paused;
}

.rc-tooltip-anim-enter.rc-tooltip-anim-enter-active,
.rc-tooltip-anim-appear.rc-tooltip-anim-appear-active {
  animation-name: rcToolTipAnimIn;
  animation-play-state: running;
}

.rc-tooltip-anim-leave.rc-tooltip-anim-leave-active,
.rc-tooltip-anim-appear.rc-tooltip-anim-appear-active {
  animation-name: rcToolTipAnimOut;
  animation-play-state: running;
}

@keyframes rcToolTipAnimIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rcToolTipAnimOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}

.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}

.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  color: var(--clr-white);
  text-align: left;
  text-decoration: none;
  font-size: 12px;
  line-height: 16px;

  background-color: #000;
  border-radius: 8px;

  padding: 8px;
  min-height: 32px;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px !important;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50% !important;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15% !important;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15% !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px !important;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50% !important;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15% !important;
  margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15% !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px !important;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50% !important;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15% !important;
  margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15% !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px !important;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50% !important;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15% !important;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15% !important;
}
