$tab: 770px;

.root {
  position: relative;
  flex: 1;

  &:not(.root_empty) {
    background: var(--thm-white);
  }
}

.root_empty {
  .header {
    background: var(--thm-white);
  }
}

.header {
  padding: 16px;

  @media (max-width: $tab) {
    padding: 12px;
  }

  &_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    button {
      max-width: 70%;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > p {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.408px;
    }
  }
}

.search {
  input {
    width: 100%;
  }
}

.observer {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 64px;
  opacity: 0;
  pointer-events: none;
}

.empty {
  height: 100%;
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  b,
  p {
    font-size: 17px;
    line-height: 23.8px;
    letter-spacing: -0.41px;
    text-align: center;

    color: var(--thm-silver-grey);
  }

  b {
    font-weight: 500;
  }

  p {
    font-weight: 400;
  }
}
