.root {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.hints {
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    text-align: center;

    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);
  }
}
