$desktop-sm: 1024px;

.root {
  border-top: 1px solid var(--thm-grey-1);
  background: var(--thm-white);

  position: relative;
  width: 100%;
}

.row {
  padding: 11px 16px;
  display: flex;
  align-items: center;

  min-height: 60px;
}

.send_btn {
  min-width: 28px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  color: var(--clr-blue);
  transition: 0.2s ease;

  &:disabled {
    color: var(--thm-grey-3);
  }

  svg {
    width: 28px;
    height: 28px;
  }
}

.textarea {
  position: relative;
  flex: 1;
  padding: 0 8px;

  textarea {
    padding: 7px 12px 8px;
    min-height: 38px;
    height: 38px;
    width: 100%;

    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;

    background: var(--thm-white);
    border-radius: 19px;
    transition: height 0.15s ease;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.textarea_attach {
  textarea {
    padding-top: 109px;
    min-height: 139px;
  }
}

.attached_list {
  z-index: 1;
  position: absolute;
  top: 12px;
  left: 20px;
  right: 9px;

  border-top-right-radius: 3px;
  overflow: hidden;
}

.status {
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  background: var(--thm-white);

  &_blue {
    color: var(--clr-blue);
  }

  &_red {
    color: var(--clr-primary);
    pointer-events: none;
  }
}
