$desktop-sm: 1024px;

.root {
  position: relative;
}

.list {
  @media (max-width: $desktop-sm) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  > li {
    @media (min-width: #{$desktop-sm + 1}) {
      margin-top: -1px;
      position: relative;
    }

    &::before {
      @media (min-width: #{$desktop-sm + 1}) {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 16px;

        width: calc(100% - 32px);
        height: 1px;
        background: var(--thm-grey-1);
      }
    }
  }
}

.observer {
  width: 100%;
  height: 200px;

  position: absolute;
  bottom: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;
}

.empty {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  text-align: center;
  color: var(--thm-silver-grey);
}
