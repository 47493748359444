.root {
  margin-top: 32px;
}

.title {
  margin-bottom: 12px;
}

.desc {
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
  margin-bottom: 16px;
}

.textarea {
  position: relative;

  > div:not(:last-child) textarea {
    margin-bottom: 52px;
  }
}

.buttons {
  position: absolute;
  right: 1px;
  bottom: 1px;

  background: var(--thm-card);
  border-top: 1px solid var(--thm-grey-1);
  border-radius: 0 0 12px 12px;

  width: calc(100% - 2px);
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.prev_btn,
.next_btn {
  button {
    width: 36px;
  }
}

.next_btn button svg {
  transform: scaleX(-1);
}

.counter {
  color: var(--thm-silver-grey);
  transition: color 0.2s ease;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;

  display: block;
  text-align: right;
  padding-top: 4px;
  padding-right: 12px;

  &_red {
    color: var(--clr-primary);
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
