.root {
  > li {
    margin-top: -1px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--thm-grey-1);
    }
  }
}

.root + .root {
  margin-top: 16px;
}
