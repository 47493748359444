$desktop-sm: 1024px;
$tab: 770px;

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.date_row {
  display: flex;
  flex-direction: column;
}

.date {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: var(--thm-black);

  padding-bottom: 16px;

  @media (max-width: $tab) {
    padding: 4px 12px;

    color: var(--thm-silver-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}

.adverts {
  @media (max-width: $tab) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.advert_wrap {
  @media (min-width: #{$tab + 1}) {
    margin-top: -1px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    position: relative;
  }

  &::before {
    @media (min-width: #{$tab + 1}) {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--thm-grey-1);
    }
  }
}

.expense_row {
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: #{$tab + 1}) {
    gap: 12px;
  }

  @media (max-width: $tab) {
    padding: 8px;
    border-radius: 12px;
    background: var(--thm-white);
  }

  &::before {
    @media (min-width: #{$tab + 1}) {
      content: '';
      z-index: -1;
      position: absolute;
      top: -16px;
      left: -16px;
      width: calc(100% + 32px);
      height: calc(100% + 32px);

      background: var(--thm-white);
      box-shadow: var(--shadow-primary);
      border: 1px solid var(--thm-grey-1);
      border-radius: 16px;
      opacity: 0;
      transition: 0.2s ease;
      pointer-events: none;
    }
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .actions {
      opacity: 1;
    }
  }
}

.expense_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $tab) {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 0.5px solid var(--thm-grey-1);
  }
}

.expense_type {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;

    color: var(--thm-black);
  }

  small {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);
  }
}

.expense_icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
  }
}

.expense_cost {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: var(--thm-black);

  @media (max-width: $tab) {
    font-weight: 400;
    letter-spacing: -0.24px;
  }
}

.advert_img {
  position: relative;
  min-width: 72px;
  width: 72px;

  @media (max-width: $tab) {
    min-width: 64px;
    width: 64px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;

    @media (max-width: $tab) {
      padding-top: 48px;
    }
  }
}

.expense_advert {
  display: flex;
  align-items: center;
  gap: 8px;
}

.img_inner {
  transition: background 0.2s ease;
  border: 1px solid var(--thm-photo-stroke);
  border-radius: 8px;
  object-fit: cover;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.advert_info {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 4px;
}

.advert_name {
  font-size: 17px;
  font-weight: 500;
  line-height: 23.8px;
  letter-spacing: -0.408px;

  color: var(--thm-black);

  display: inline-flex;
  align-items: center;
  gap: 8px;

  @media (max-width: $desktop-sm) {
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
  }

  @media (max-width: $tab) {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  span {
    padding: 2px 4px;
    border-radius: 4px;

    background-color: var(--thm-grey-1);
    color: var(--thm-silver-grey);

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.advert_price {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-black);
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;

  transition: 0.2s ease all;
  opacity: 0;

  @media (max-width: $tab) {
    display: none;
  }
}
