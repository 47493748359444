.root {
  width: 676px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  letter-spacing: 0.36px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 28px;
    height: 28px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.hr {
  width: 100%;
  height: 1px;
  background: var(--thm-grey-1);
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.details,
.reason,
.result {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;

  > div {
    flex: 1;
  }
}

.counter {
  color: var(--thm-silver-grey);

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;

  display: block;
  text-align: right;
  padding-top: 4px;
  padding-right: 12px;
}

.result_row {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 8px;

  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
  text-align: left;

  &_open {
    .chevron {
      transform: rotate(0);
    }
  }

  &:not(:last-child) {
    > span {
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    > span:first-child {
      color: var(--thm-silver-grey);
    }
  }

  b {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
  }
}

.result_line {
  flex: 1;
  border-top: 0.5px dashed var(--thm-silver-grey-1);
  transform: translateY(-4px);
}

.result_toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.tariffs {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  border: 1px solid var(--thm-silver-grey-2);

  background: var(--thm-grey-1);
  border-radius: 16px;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s ease;
  transform: rotate(180deg);
}

.date {
  position: relative;

  &_btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  > div:not(:last-child) {
    pointer-events: none;
  }
}
