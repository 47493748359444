$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 16px;

  @media (max-width: $mob) {
    margin-bottom: 12px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
