.select_card {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.408px;

  color: var(--thm-silver-grey);
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.brand {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.408px;

  color: var(--thm-black);
}

.number {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  color: var(--thm-silver-grey);
}
