.root {
  max-width: 628px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
