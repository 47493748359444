.root {
  max-width: 654px;
  width: 100%;
  margin: 0 auto;
  padding: 36px 0;

  min-height: calc(100vh - var(--header-h) - var(--navbar-h));
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h));
  display: flex;
  align-items: center;

  :global {
    .box {
      border-radius: 24px;
    }
  }
}

.container {
  width: 100%;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.back_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: var(--thm-grey-1);
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.84;
  }
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;

  span {
    color: var(--thm-silver-grey);
  }
}

.btn {
  margin-top: 24px;
}

.created {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > div {
    width: 120px;
    height: 120px;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.36px;

    margin: 12px 0;
  }

  p {
    color: var(--thm-silver-grey);
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    white-space: pre-wrap;
  }

  button {
    margin-top: 24px;
  }
}
