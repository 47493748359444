.root {
  position: relative;

  flex: 1;
}

.list {
  > li {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;

      width: calc(100% - 62px);
      height: 1px;
      display: none;

      background: var(--thm-grey-2);
    }

    &:not(:last-child)::before {
      display: block;
    }
  }
}

.observer {
  width: 100%;
  height: 64px;

  position: absolute;
  bottom: 0;
  left: 0;
}
