.root {
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  position: relative;
  overflow-y: auto;
}

.root_smooth {
  scroll-behavior: smooth;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.messages {
  padding: 16px;
}

.messages,
.messages > section {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.date_divider {
  align-self: center;
  margin: 8px 0;

  z-index: 2;
  position: sticky;
  top: 8px;

  p {
    padding: 4px 8px;

    background: rgba(69, 69, 69, 0.24);
    backdrop-filter: blur(2px);
    border-radius: 40px;

    text-align: center;
    color: var(--clr-white);
    font-size: 12px;
    line-height: 16px;
  }
}

.unread {
  background: var(--thm-white);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);

  padding: 4px 0;
  margin: 8px 0 8px -16px;
  width: calc(100% + 32px);

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  text-align: center;
  color: var(--thm-silver-grey);
}
