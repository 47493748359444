.modal_container {
  border-radius: 0;

  padding: 0 !important;
  max-height: 100%;
  height: 100%;
  width: 240px !important;
  margin-left: auto;
}

.root {
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  overflow-y: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.onboarding {
  padding: 8px;
  margin-top: auto;
}

.close_btn {
  padding: 8px 8px 12px;
  margin-top: auto;
}
