.root {
  margin-bottom: 24px;
}

.logo_wrap {
  position: relative;
}

.logo {
  position: relative;
}

.logo_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;

  border-radius: 50%;
  border: 4px solid var(--thm-white);

  overflow: hidden;
}

.edit_btn {
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--thm-grey-1);
  box-shadow: var(--shadow-primary);

  background-color: var(--thm-white);
}
