$tab: 770px;

.root_modal {
  overflow: visible;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: #{$tab + 1}) {
    width: 346px;
  }
}

.description,
.schedule {
  display: flex;
  flex-direction: column;
  gap: 12px;

  p,
  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.caption {
  color: var(--thm-silver-grey);
}
