$desktop-lg: 1300px;
$desktop: 1024px;
$tab: 770px;
$excluded-pages: '.root_adverts, .root_wallet, .root_creditreq, .root_callback, .root_overview';

.root {
  position: relative;
  display: flex;
  align-items: flex-start;

  --header-h: 61px;
  --main-content-padding-x: 24px;
  --main-content-padding-y: 24px;

  @media (max-width: $tab) {
    --main-content-padding-x: 16px;
  }
}

.sidebar {
  z-index: 9;
  position: sticky;
  top: 0;

  background: var(--thm-white);
  box-shadow: var(--shadow-primary);

  min-width: var(--sidebar-w);
  width: var(--sidebar-w);
  border-right: 1px solid var(--thm-grey-1);

  @media (max-width: $desktop) {
    display: none;
  }
}

.sidebar_minimized {
  min-width: var(--sidebar-min-w);
  width: var(--sidebar-min-w);
}

.container {
  position: relative;
  width: calc(100% - var(--sidebar-w));

  @media (max-width: $desktop) {
    width: 100%;
  }

  &_sidebar_minimized {
    @media (min-width: #{$desktop + 1}) {
      width: calc(100% - var(--sidebar-min-w));
    }
  }
}

.root:not(.root_chat) {
  .main {
    padding: var(--main-content-padding-y) var(--main-content-padding-x);
  }

  &:not(#{$excluded-pages}) {
    .inner {
      max-width: 1280px;
    }
  }
}

.root_tariffs {
  .inner {
    max-width: 1600px !important;
  }
}

.root_settings,
.root_contacts,
.root_reviews,
.root_roles,
.root_addresses {
  .inner {
    max-width: unset !important;
    width: 100%;
  }
}

.main {
  @media (max-width: $desktop) {
    min-height: calc(100vh - var(--header-h) - var(--navbar-h));
  }
}

.toggle_btn_pos,
.toggle_btn_wrap,
.toggle_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle_btn_wrap {
  position: relative;
}

.toggle_btn {
  height: 28px;
  width: 19px;

  border-radius: 6px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);
  transition: 0.15s ease;

  color: var(--thm-silver-grey);

  svg {
    transform-origin: 50% 50%;
    transform: scaleX(-1);
  }
}

.toggle_btn_pos {
  z-index: 101;
  position: absolute;
  top: 16px;
  left: var(--sidebar-w);
  transform: translateX(-50%);

  transition: opacity 0.15s ease, box-shadow 0.15s ease;
  opacity: 0;

  &_min {
    left: var(--sidebar-min-w);

    svg {
      transform: scaleX(1);
    }
  }
}

.toggle_btn_pos:hover {
  opacity: 1;

  .toggle_btn {
    box-shadow: var(--shadow-primary);
  }
}

.sidebar:hover ~ .toggle_btn_pos {
  opacity: 1;
}
