.root {
  min-width: 40px;
  border-radius: 8px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);

  > li {
    &:not(:last-child) {
      border-bottom: 1px solid var(--thm-grey-1);
    }
  }

  &:empty {
    display: none;
  }
}

.action_btn {
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--thm-silver-grey);
  transition: 0.2s ease;

  &:hover:not(.action_btn_red) {
    color: var(--thm-black);
  }

  &_red {
    color: var(--clr-primary);
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.more_wrap {
  position: relative;
}

.dropdown_btn {
  white-space: nowrap;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.408px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.dropdown_btn_danger {
  color: var(--clr-primary);
}
