.root {
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 100%;
}

.description {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;

  color: var(--thm-black);
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.chips {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  & > {
    div {
      flex: 1;
    }
  }
}

.promocode {
  position: relative;

  small {
    display: block;
    text-align: right;
    margin: 4px 12px 0 auto;

    font-size: 12px;
    line-height: 16px;
    color: var(--thm-silver-grey);
  }
}
