$tab: 770px;

.list {
  width: 100%;
  padding-top: 16px;

  > li {
    margin-top: -1px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--thm-grey-1);
    }
  }

  > li:not(:last-child) {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: $tab) {
    flex-direction: column;
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: -16px;
    left: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);

    background: var(--thm-white);
    border: 1px solid var(--thm-grey-1);
    box-shadow: var(--shadow-primary);
    border-radius: 16px;
    opacity: 0;
    transition: 0.2s ease;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .name {
      color: var(--clr-blue);
    }
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.img {
  position: relative;
  min-width: 234px;
  width: 234px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;
  }
}

.img_inner {
  transition: background 0.2s ease;

  border: 1px solid var(--thm-photo-stroke);
  border-radius: 8px;
  object-fit: cover;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: var(--thm-card);
}

.status {
  border-radius: 4px;
  padding: 4px 6px 4px 6px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--clr-white);
  background: var(--clr-primary);

  position: absolute;
  top: 4px;
  left: 4px;
}

.content {
  flex: 1;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4px;
}

.specs {
  display: flex;
  align-items: center;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  span {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.41px;
    margin: 0 4px;
    transform: translateY(-1px);
  }
}

.color {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;

  &_white {
    border: 1px solid var(--thm-photo-stroke);
  }
}

.total {
  text-align: right;
  margin-left: auto;
  min-width: 155px;

  @media (max-width: $tab) {
    margin-left: unset;
  }

  b {
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.35px;
  }

  span {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }
}

// Categories
.categories > li:not(:last-child) {
  margin-bottom: 8px;
}

.category {
  display: flex;
  align-items: center;
  gap: 8px;
}

.category_name {
  display: flex;
  align-items: center;
  gap: 0.3em;

  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.41px;

  b {
    font-weight: 600;
  }
}

.category_color {
  margin-right: calc(8px - 0.3em);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
}

.promo_logo {
  margin-right: calc(8px - 0.3em);
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.category_separator {
  color: var(--thm-silver-grey);
}

.category_line {
  flex: 1;
  align-self: flex-end;
  border-bottom: 1px dashed var(--thm-grey-3);
  transform: translateY(-4px);
}

.category_total {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;
}
