.root {
  color: var(--doc-color);

  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 6px 4px 4px;
  gap: 8px;
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 48px;
  width: 48px;
  height: 48px;

  border-radius: 12px;
  background: var(--doc-color);
  color: var(--clr-white);
}

.text {
  width: calc(100% - 56px);
}

.name {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats {
  display: flex;

  &:empty {
    display: none;
  }

  > li {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;

      width: 3px;
      height: 3px;
      display: none;

      background: var(--thm-silver-grey);
      border-radius: 50%;
    }

    &:not(:first-child) {
      padding-left: 19px;

      &::before {
        display: block;
      }
    }
  }

  p {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: var(--thm-silver-grey);
  }
}

.root_own {
  .stats p {
    color: var(--clr-onbubble);
  }

  .icon {
    background: var(--clr-onbubble);
  }
}

.root_compact {
  padding: 12px;

  .icon {
    border-radius: 50%;

    min-width: 42px;
    width: 42px;
    height: 42px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .text {
    width: calc(100% - 50px);
  }

  .name {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
  }
}
