$desktop-lg: 1300px;
$tab: 770px;

.filter {
  max-width: 320px;
  margin-bottom: 24px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: $tab) {
    gap: 12px;
  }

  > li {
    width: calc(33.33% - 11px);
    min-height: 378px;

    @media (max-width: $desktop-lg) {
      width: calc(50% - 8px);
    }

    @media (max-width: $tab) {
      width: 100%;
    }
  }
}

.list h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.wallet {
  height: 100%;

  > div {
    min-height: unset !important;
    height: 100%;
  }
}
