.root {
  position: relative;
  z-index: 1;

  overflow: hidden;
  border-radius: 17px;
}

.play_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(4px);
  border-radius: 50%;

  color: var(--clr-white);

  svg {
    width: 8px;
    height: 8px;
  }
}

.time {
  position: absolute;
  top: 8px;
  left: 8px;
  max-width: calc(100% - 16px);

  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.066px;
  color: var(--clr-white);

  padding: 2px 6px;

  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(4px);
  border-radius: 20px;
}

.preview {
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
  }
}

.preview_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 2px);
  object-fit: cover;
  border-radius: 2px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -2px;
  margin-left: -2px;

  > li {
    margin-top: 2px;
    margin-left: 2px;
  }
}

.list[data-count='1'] {
  > li {
    width: calc(100% - 2px);
  }

  .preview::before {
    padding-top: 76.92%;
  }
}

.list[data-count='2'] {
  > li {
    width: calc(50% - 2px);
  }

  .preview::before {
    padding-top: 155%;
  }
}

.list[data-count='3'] {
  > li {
    &:first-child {
      width: calc(100% - 2px);

      .preview::before {
        padding-top: 38.07%;
      }
    }

    &:not(:first-child) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }
  }
}

.list[data-count='4'] {
  > li {
    width: calc(50% - 2px);
  }

  .preview::before {
    padding-top: 76.744%;
  }
}

.list[data-count='5'] {
  > li {
    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: calc(33.33% - 2px);

      .preview::before {
        padding-top: 116%;
      }
    }
  }
}

.list[data-count='6'] {
  > li {
    &:nth-child(1) {
      width: calc(100% - 2px);

      .preview::before {
        padding-top: 38.07%;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: calc(33.33% - 2px);

      .preview::before {
        padding-top: 116%;
      }
    }
  }
}

.list[data-count='7'] {
  > li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      width: calc(33.33% - 2px);

      .preview::before {
        padding-top: 116%;
      }
    }
  }
}

.list[data-count='8'] {
  > li {
    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      width: calc(33.33% - 2px);

      .preview::before {
        padding-top: 116%;
      }
    }
  }
}

.list[data-count='9'] {
  > li {
    &:nth-child(1) {
      width: calc(100% - 2px);

      .preview::before {
        padding-top: 38.07%;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      width: calc(33.33% - 2px);

      .preview::before {
        padding-top: 116%;
      }
    }
  }
}

.list[data-count='10'] {
  > li {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: calc(50% - 2px);

      .preview::before {
        padding-top: 76.744%;
      }
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      width: calc(33.33% - 2px);

      .preview::before {
        padding-top: 116%;
      }
    }
  }
}
