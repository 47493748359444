.wallet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 45.5px;
  height: 100%;

  header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.35px;
  }
}

.wallet,
.wallet_amount {
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);
  }
}

.wallet_amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  b {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0.37px;
  }
}

.blue {
  color: var(--clr-blue);
}

.popup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
