.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.count {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: var(--thm-silver-grey);

  &_active {
    color: var(--clr-blue);
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    color: var(--thm-silver-grey-1);
  }

  &_active {
    svg {
      color: var(--clr-blue);
    }
  }
}

.btn {
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s ease;

  &_prev {
    svg {
      transform: scale(1, -1);
    }
  }

  &:disabled {
    svg {
      color: var(--thm-silver-grey-1);
    }
  }
}
