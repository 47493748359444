.list {
  --padding: 12px;
  --gap: 12px;
  --img-width: 85px;

  > li {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      height: 1px;
      width: calc(100% - var(--padding) - var(--gap) - var(--img-width));
      background-color: var(--thm-photo-stroke);

      transition: 0.2s ease all;
    }

    &::before {
      top: unset;
      bottom: 0;
      background-color: transparent;
    }

    &:first-of-type::after {
      background-color: transparent;
    }

    &:last-of-type::before {
      background-color: var(--thm-photo-stroke);
    }

    &:is(.active),
    &:hover {
      &::after,
      & + li::after {
        background-color: transparent;
      }
    }

    &:is(.active),
    &:hover:last-of-type {
      &::before {
        background-color: transparent;
      }
    }
  }
}
