$desktop-sm: 1024px;

.root {
  > li:not(:last-child) {
    margin-bottom: 8px;
  }

  :global {
    .ReactCollapse--content {
      padding-top: 8px;
    }
  }

  @media (max-width: $desktop-sm) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.header_btn {
  border-radius: 8px;
  background: var(--thm-grey-1);

  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 24px;
  gap: 4px;

  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);
  text-transform: uppercase;

  svg {
    width: 16px;
    height: 16px;
    transition: 0.2s ease;
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  }

  &_active svg {
    transform: rotate(0deg);
  }
}
