.root {
  max-width: 1146px;
}

.cropper {
  max-width: 50vw;
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    flex: 1;
  }
}
