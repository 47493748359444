.root {
  text-align: center;

  &:not(.root_compact) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 340px;
    margin-bottom: 24px;
  }
}

.lottie {
  width: 340px;
  height: 340px;

  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  word-break: break-word;
  margin-bottom: 12px;
}

.text {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);

  max-width: 32em;
  margin: 0 auto 24px;
}

.root_compact {
  text-align: left;

  img {
    width: 80px;
    margin-right: 12px;
    margin-bottom: 0;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
    margin-bottom: 4px;
  }

  .text {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    margin-bottom: 0;
  }

  a,
  button {
    margin-top: 16px;
  }
}
