$tab: 770px;
$mob: 600px;

.modal_root {
  .modal_container.modal_container_imp {
    @media (min-width: #{$tab + 1}) {
      padding-top: 0;
    }
  }

  &:not([data-open='true']) {
    .modal_container.modal_container_imp {
      @media (min-width: #{$tab + 1}) {
        transform: translateX(101%);
      }
    }
  }

  &[data-open='true'] {
    .modal_container.modal_container_imp {
      @media (min-width: #{$tab + 1}) {
        transform: translateX(0%);
      }
    }
  }
}

.modal_container.modal_container_imp {
  width: 100%;
  padding: 24px 24px 0;

  @media (min-width: #{$tab + 1}) {
    max-width: 1050px;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);

    align-self: flex-start;
    margin-left: auto;

    background: var(--thm-white);
    border-left: 1px solid var(--thm-grey-1);
    box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.03);
    border-radius: 0;
  }
}

.root {
  @media (max-width: $mob) {
    min-height: calc(100vh - 32px);
    min-height: calc(var(--vh, 1vh) * 100 - 32px);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: calc(100% + 48px);
  margin: 0 0 24px -24px;
  padding: 24px;
  border-bottom: 1px solid var(--thm-grey-1);
  background: var(--thm-white);

  position: sticky;
  top: 0;
  z-index: 3;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.36px;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
  margin-bottom: 4px;
}

.hr {
  margin: 12px 0 32px;
  width: 100%;
  height: 1px;
  background: var(--thm-grey-1);
}

.spinner {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Options
.options,
.checkboxes {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;

  > li {
    margin-left: 12px;
    width: calc(33.33% - 12px);

    @media (max-width: $tab) {
      width: calc(50% - 12px);
    }

    @media (max-width: $mob) {
      width: calc(100% - 12px);
    }

    &.checkboxes_li {
      width: calc(100% - 12px);
    }
  }
}

.options {
  margin-top: -16px;

  > li {
    margin-top: 16px;
  }
}

.checkbox {
  padding: 12px 0;
}

.select {
  position: relative;

  ul {
    max-height: 212px !important;
  }
}

// Bottom
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  margin-top: 24px;

  z-index: 1;
  position: sticky;
  bottom: 0;
  width: calc(100% + 48px);
  margin-left: -24px;

  background: var(--thm-white);
  border-top: 1px solid var(--thm-grey-1);
}

.reset_btn {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.408px;
  color: var(--clr-primary);

  svg {
    width: 24px;
    height: 24px;
  }
}
