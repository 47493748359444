$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.label {
  padding: 4px 12px;
  border-radius: 24px;

  background-color: var(--clr-primary);

  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;

  color: var(--clr-white);
}

.title {
  span {
    color: var(--thm-silver-grey);
  }
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.upload_container {
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: stretch;
  justify-content: center;

  min-width: 260px;
  width: 260px;
  aspect-ratio: 0.5625;

  border-radius: 12px;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.desc_title {
  font-size: 17px;
  font-weight: 500;
  line-height: 23.8px;
  letter-spacing: -0.41px;

  color: var(--thm-black);
}

.info {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;

  color: var(--thm-silver-grey);

  b {
    color: var(--thm-black);
    font-weight: 400;
  }
}

.poster {
  position: absolute;
  top: -20px;

  width: 110%;
  height: 110%;

  filter: blur(8px);
  -webkit-filter: blur(8px);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.poster_img {
  align-self: center;
  width: 100%;
  height: auto;
  z-index: 1;
}

.remove_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
  position: absolute;
  top: 4px;
  right: 4px;

  width: 36px;
  height: 36px;

  background-color: var(--thm-grey-1);
  border-radius: 50%;
  transition: 0.2s ease all;

  svg {
    color: var(--thm-black);
  }

  &:hover {
    background-color: var(--clr-blue);

    svg {
      color: var(--clr-white);
    }
  }

  &_reel {
    top: 12px;
    right: 12px;
    width: 52px;
    height: 52px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.btn {
  margin-top: 16px;
}
