$tab: 770px;

.root,
.cover,
.cover_img,
.upload_btn > button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cover_img {
  object-fit: cover;
}

.upload_root {
  @media (min-width: #{$tab + 1}) {
    width: 720px;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.36px;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}

.root {
  background: var(--thm-white);
}

.hr {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: var(--thm-grey-1);
}

.upload_modal_btn {
  background: var(--thm-white);
}

.upload_modal_btn button {
  margin-top: 12px;
  width: 100%;
  height: 240px;
  border: 2px dashed var(--clr-blue-15);
  border-radius: 12px;
}

.cropper_header {
  margin-bottom: 24px;

  > p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}

.cropper_header_row {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.cropper_header_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-left: auto;
  }

  &,
  svg {
    width: 28px;
    height: 28px;
  }
}

.cropper_header_title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;
}
