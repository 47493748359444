.root {
  max-width: 676px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 24px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;

  span {
    color: var(--thm-silver-grey);
  }
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  margin-top: 24px;
}
