.wrapper {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  transition: 0.2s ease;
  transform: translateX(101%);
  pointer-events: none;

  &_open {
    transform: translateX(0%);
    pointer-events: auto;
  }

  .root {
    height: 100%;
  }
}

.root {
  background: var(--thm-bg);

  display: flex;
  flex-direction: column;

  overflow-y: auto;
}

.box {
  padding: 24px 16px 0 16px;
}

.btn_top {
  position: absolute;
  padding: 8px;
  margin: 16px;
  bottom: 0;
  right: 0;
  line-height: 0;

  border: 1px solid var(--thm-grey-2);
  border-radius: 50%;
  box-shadow: 0px 0px 12px 0px #00000014;

  background-color: var(--thm-white);
}
