$tab: 770px;

.root {
  position: relative;

  flex: 1;
}

.list {
  display: flex;
  flex-wrap: wrap;

  margin-top: -3px;
  margin-left: -3px;

  > li {
    display: flex;
    width: calc(25% - 3px);
    margin-top: 3px;
    margin-left: 3px;

    @media (max-width: $tab) {
      width: calc(33.33% - 3px);
    }
  }
}

.preview {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.preview_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(4px);
  border-radius: 50%;

  color: var(--clr-white);

  svg {
    width: 8px;
    height: 8px;
  }
}

.observer {
  width: 100%;
  height: 64px;

  position: absolute;
  bottom: 0;
  left: 0;
}
