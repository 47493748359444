$desktop-sm: 1110px;
$tab: 770px;

.filter {
  position: relative;

  > div > ul {
    @media (min-width: #{$tab + 1}) {
      max-height: 421px !important;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  gap: 4px;

  min-height: 36px;
  padding: 8px 12px;

  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--thm-grey-1) inset;
  background: var(--thm-card);
  transition: 0.2s ease;

  span {
    white-space: nowrap;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  svg {
    width: 20px;
    height: 20px;

    transform-origin: 50% 50%;
    transition: transform 0.2s ease;
    color: var(--thm-silver-grey);

    &:not(:first-child) {
      margin-left: auto;
    }
  }

  &:hover {
    box-shadow: 0 0 0 1px var(--clr-blue) inset;
  }
}

.btn_open {
  box-shadow: 0 0 0 1px var(--clr-blue) inset;
}

.btn_selected {
  svg {
    color: var(--thm-black);
  }
}

.root_open:not(.root_labeled) {
  .btn {
    svg {
      transform: rotate(180deg);
    }
  }
}

.root_labeled {
  .btn {
    padding: 26px 12px 8px;

    svg {
      transform: translateY(-6px);
    }
  }
}
