.wrap {
  position: relative;
  width: fit-content;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;

  min-height: 20px;
  padding: 2px 8px;
  border-radius: 12px;

  background-color: var(--level-bg);
  box-shadow: 0 0 0 1px var(--level-bg) inset;

  z-index: 1;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    color: var(--level-primary);
  }
}

.levels_container {
  display: flex;
  align-items: flex-end;
  gap: 2px;
}

.level {
  width: 2px;
  border-radius: 1px;
  background-color: var(--level-bg);

  &:nth-child(1) {
    height: 5px;
  }

  &:nth-child(2) {
    height: 8px;
  }

  &:nth-child(3) {
    height: 10px;
  }
}

.active {
  background-color: var(--level-primary);
}

.dropdown {
  ul {
    padding: 0 !important;
  }
}

.info {
  padding: 24px;
  width: 382px;

  > h3 {
    margin-top: 4px;
    margin-bottom: 8px;

    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.38px;

    color: var(--thm-black);
  }

  > p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);

    b {
      font-weight: 400;
      color: var(--thm-black);
    }
  }
}

.dropdown_anchor {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
