$mob: 770px;

.root {
  &:not(.root_modal) {
    z-index: 2;
    position: absolute;
    right: 0;
    top: calc(100% + 12px);
    height: fit-content;

    overflow: hidden;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    background: var(--thm-white);
    box-shadow: var(--shadow-primary);
    border: 1px solid var(--thm-grey-1);
    border-radius: 12px;

    .list {
      padding: 8px;
      max-height: 296px;
      overflow-y: auto;
    }

    &.root_top {
      top: unset;
      bottom: calc(100% + 12px);
    }
  }
}

.li {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.li > * {
  width: 100%;
  padding: 12px;

  text-align: left;
  background: 0;
  transition: 0.2s ease;
  border-radius: 8px;
}

.root:not(.root_nohover) {
  .li > *:hover {
    @media (hover: hover) {
      background: var(--thm-grey-1);
    }
  }

  .li[data-active='true'] > * {
    background: var(--thm-grey-1);
  }
}

.modal_container.modal_container_imp {
  padding: 8px 0;
}

.root_open:not(.root_modal) {
  opacity: 1;
  pointer-events: auto;
}

.root_clickable:not(.root_modal) {
  pointer-events: auto;
}

.root_left:not(.root_modal) {
  right: unset;
  left: 0px;

  &:not(.root_fullwidth) {
    width: fit-content;
  }
}

.root_fullwidth {
  &,
  .list {
    width: 100%;
  }
}

.root_fullheight {
  &,
  .list {
    max-height: unset !important;
  }
}

.actions_modal {
  padding: 36px 16px !important;

  background-color: var(--thm-grey-1);

  > div {
    background-color: var(--thm-white);
    border-radius: 12px;
  }
}

.actions_list {
  li {
    margin-left: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--thm-grey-1);
    }

    button {
      width: 100%;
      padding: 14px 16px 14px 0;
    }
  }
}

.anchor_fullwidth {
  width: 100%;
  position: absolute;
}
