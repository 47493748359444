.icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 16px;
}

.title {
  text-align: center;
  margin-bottom: 8px;

  span {
    white-space: nowrap;
  }
}

.subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;

  max-width: 17em;
  margin: 0 auto 24px;
}

.code_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  text-align: center;

  margin-bottom: 36px;

  span {
    display: block;
    white-space: nowrap;
    font-weight: 600;
  }
}

.field {
  margin: 24px 0;
}

.field_email {
  margin: 12px 0;
}

.field_password {
  margin: 12px 0 24px 0;
}

.restore_password {
  display: block;
  width: fit-content;
  margin: 4px 12px 0 auto;

  text-align: right;
  color: var(--clr-blue);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
}

.resend {
  text-align: center;
  margin: 36px 0;

  &_btn {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--clr-blue);
  }

  > p {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-grey-3);
  }
}

.buttons {
  button:not(:last-child) {
    margin-bottom: 12px;
  }
}
