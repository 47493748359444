.dropdown {
  z-index: 7 !important;

  @media (max-width: 1480px) {
    left: unset !important;
    right: 0 !important;
  }

  > ul {
    padding: 0 !important;
    max-height: unset !important;
    border-radius: 24px !important;
  }
}

.root {
  width: 394px;
  padding: 24px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;
  text-align: left;

  color: var(--thm-black);

  margin-bottom: 16px;
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.label {
  margin-bottom: 8px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  font-weight: 500;
}

.error,
.text {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);

  display: block;
  padding: 0 12px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.desc,
.text {
  color: var(--thm-silver-grey);
}

.error {
  color: var(--clr-primary);
}

.field {
  margin-bottom: 4px;
}

.discounts {
  margin: 16px 0;

  .field {
    margin: 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.38px;
    text-align: left;

    color: var(--thm-black);
  }
}

.types {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:empty {
    display: none;
  }
}
