$mob: 770px;

.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
  margin-left: -12px;

  > li {
    margin-top: 12px;
    margin-left: 12px;
    width: calc(33.33% - 12px);

    @media (max-width: $mob) {
      width: calc(50% - 12px);
    }
  }
}

.body_btn {
  background: var(--thm-white);
  border: 1px solid var(--thm-grey-1);
  border-radius: 12px;
  transition: 0.2s ease;
  color: var(--thm-silver-grey);

  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $mob) {
    padding: 12px;
  }

  &:hover,
  &_active {
    border: 1px solid var(--clr-blue);
    color: var(--clr-blue);
  }

  &_active {
    background: var(--clr-blue-15);
  }

  img {
    max-width: 160px;
    width: 100%;
    height: auto;
  }

  span {
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.408px;

    @media (max-width: $mob) {
      font-size: 12px;
    }
  }
}
