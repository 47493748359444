$desktop-sm: 1024px;

.root {
  width: 100%;
  padding: 24px;

  border-radius: 16px;
  transition: 0.2s ease;
  background-color: var(--thm-white);
  border: 1px solid var(--thm-grey-1);

  @media (max-width: $desktop-sm) {
    padding: 16px;
  }

  &:not(.root_flat) {
    box-shadow: var(--shadow-primary);
  }

  &_flat {
    &:hover {
      background-color: var(--thm-card);
      box-shadow: var(--shadow-primary);
    }
  }
}
