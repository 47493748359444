$tab: 770px;

.root {
  min-height: calc(100vh - var(--header-h));
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h));
  padding: 36px var(--pad);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $tab) {
    min-height: calc(100vh - var(--header-h) - var(--navbar-h));
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h));
  }
}

.inner {
  width: 100%;

  @media (min-width: #{$tab + 1}) {
    max-width: 454px;
  }
}

.policy {
  margin-top: 24px;
  padding: 0 24px;

  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--thm-silver-grey);

  a {
    color: var(--clr-blue);
  }
}
