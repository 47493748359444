$desktop: 1300px;
$desktop-sm: 1024px;

.box {
  min-width: 413px;
  width: 413px;
  padding: 24px;

  background: var(--thm-white);
  box-shadow: var(--shadow-primary);
  border-radius: 16px;
  border: 1px solid var(--thm-grey-1);

  @media (max-width: $desktop) {
    min-width: 330px;
    width: 330px;
    padding: 16px;
  }

  @media (max-width: $desktop-sm) {
    min-width: unset;
    width: 100%;
  }
}

.box_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.35px;

  margin-bottom: 24px;
}

.result_list {
  margin: 16px 0 24px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  span {
    color: var(--thm-silver-grey);
    margin-right: 0.3em;
  }

  > li {
    &:not(:first-child) {
      margin-top: 12px;
    }

    &:last-child .result_row {
      justify-content: space-between;
    }

    &.separate {
      margin-top: 24px;
    }
  }
}

.result_row {
  display: flex;
  align-items: flex-end;

  b {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
  }
}

.result_line {
  flex: 1;
  border-top: 1px dashed var(--thm-silver-grey-1);
  margin: 0 8px;

  transform: translateY(-4px);
}
