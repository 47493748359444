.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background-color: var(--clr-blue-15);

  width: 100%;

  transition: 0.2s ease all;

  &:hover {
    opacity: 0.84;
  }
}

.upload_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 100%;
  height: 100%;

  span,
  p,
  svg {
    color: var(--clr-blue);
  }

  span,
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: center;
  }
}
