.field {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.field_phone {
  position: relative;
  input:disabled {
    color: var(--thm-silver-grey);
  }
}

.edit_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;

  color: var(--clr-blue);
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.41px;

  display: flex;
  align-items: center;
}

.text {
  margin-top: 24px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  a {
    color: var(--clr-blue);
  }
}
