$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;
$mob: 520px;

:root {
  --pad: 16px;
  --sidebar-w: 233px;
  --sidebar-min-w: 61px;
  --navbar-h: 0;

  @media (max-width: $desktop-sm) {
    --navbar-h: calc(env(safe-area-inset-bottom) + 41px);
  }

  @media (max-width: $tab) {
    --navbar-h: calc(env(safe-area-inset-bottom) + 57px);
  }

  @media (max-width: $desktop) and (min-width: #{$tab + 1}) {
    --pad: 24px;
  }
}

// Container
.container {
  margin: 0 auto;
  padding: 0 var(--pad);
  max-width: 1432px;
}

html,
body {
  width: 100%;
  background-color: var(--thm-white-bg);

  @media (max-width: $desktop) {
    background-color: var(--thm-bg);
  }

  a {
    text-decoration: none;
  }
}

.box {
  padding: 24px;
  background: var(--thm-white);
  border: 1px solid var(--thm-grey-1);
  box-shadow: var(--shadow-primary);
  border-radius: 16px;

  @media (max-width: $desktop-sm) {
    padding: 16px;
  }
}

// Scroll lock
.hide-scrollbar {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-scrollbar {
  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--thm-card);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--thm-grey-2);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--thm-grey-2);
  }
}

body.no-scroll {
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  &:not(.is-firefox) {
    position: fixed;
  }
}

// Collapse
.ReactCollapse--collapse {
  transition: height 0.3s ease;
}

// Nprogress
#nprogress {
  .bar {
    background: var(--clr-blue);
  }
  .peg {
    box-shadow: 0 0 10px var(--clr-blue), 0 0 5px var(--clr-blue);
  }
}

// Skeleton
.skeleton-container {
  line-height: 1;

  br {
    display: none;
  }
}

// Emoji mart
.emoji-mart-bar:last-child {
  display: none;
}

.emoji-mart {
  border-color: transparent;
}

// Sonner
[data-x-position='center'] {
  @media (max-width: 600px) {
    width: 100%;
  }
}

[data-x-position='center'] [data-sonner-toast] {
  @media (max-width: 600px) {
    width: calc(100% - 32px) !important;
  }
}

[data-sonner-toaster][data-y-position='top'] {
  @media (max-width: 600px) {
    top: calc(env(safe-area-inset-top) + 24px) !important;
  }
}
