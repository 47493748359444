.root {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  padding: 16px 24px;
  margin: 0 16px 24px;

  border-radius: 8px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);
}

.text {
  flex: 1;
}

.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;

  display: block;
  margin-bottom: 4px;
}

.subtitle {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);
}
