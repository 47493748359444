.emoji_wrap {
  position: relative;

  button {
    svg {
      transition: 0.2s ease all;
      color: var(--thm-silver-grey);

      &:hover {
        color: var(--thm-black);
      }
    }
  }
}
