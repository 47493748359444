$mob: 770px;

.field {
  margin-bottom: 20px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.36px;

  margin-top: 32px;
  margin-bottom: 16px;

  @media (max-width: $mob) {
    margin-top: 24px;
  }
}

.list {
  > li {
    border-top: 1px solid var(--thm-grey-1);
    padding-top: 16px;

    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }
}

.save_btn {
  margin-top: 20px;
}
