$tab: 770px;

.root {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.wrap {
  position: relative;

  button {
    @media (max-width: $tab) {
      width: 48px;
    }

    & > div > span {
      @media (max-width: $tab) {
        display: none;
      }
    }
  }
}

.lang_btn {
  gap: 8px;

  &,
  span {
    display: flex;
    align-items: center;
  }

  span {
    justify-content: center;
  }

  svg {
    width: 20px;
    height: 20px;
    color: var(--clr-blue);
  }
}
