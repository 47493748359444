$tab: 770px;

.root {
  position: relative;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  background: var(--thm-white);

  border-bottom: 1px solid var(--thm-grey-1);

  &.root_nochat {
    gap: 12px;
    padding: 12px 16px;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  &:not(.root_nochat) {
    padding: 4px 12px 4px 6px;

    @media (min-width: #{$tab + 1}) {
      flex-direction: row-reverse;
      padding: 12px 16px;
    }
  }
}

.profile_btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.back {
  position: relative;
  z-index: 1;

  @media (min-width: #{$tab + 1}) {
    display: none;
  }
}

.root:not(.root_nochat) {
  .text {
    @media (min-width: #{$tab + 1}) {
      white-space: nowrap;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    @media (max-width: $tab) {
      text-align: center;
      width: 50%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.root_nochat .text {
  width: calc(100% - 44px - 24px - 28px);
}

.name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  color: var(--thm-black);

  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);

  &_online {
    color: var(--clr-green);
  }
}

.root_nochat .status {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.ad {
  margin-right: auto;

  @media (max-width: $tab) {
    display: none;
  }
}

.ad_title {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;

  color: var(--thm-black);
}

.ad_stats {
  display: flex;
  align-items: center;

  > li {
    position: relative;
    white-space: nowrap;

    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: var(--thm-silver-grey);

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(:first-child) {
      padding-left: 20px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;

        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--thm-silver-grey);
      }
    }
  }
}
