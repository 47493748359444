.root {
  box-shadow: var(--shadow-primary);
  border-radius: 16px;
  overflow: hidden;
}

.cover {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background: var(--thm-grey-2);

  &::before {
    content: '';
    display: block;
    padding-top: 46.6%;
  }
}

.subheader {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 0 16px 20px;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: var(--shadow-primary);
  background-color: var(--thm-white);

  &::before {
    content: '';
    position: absolute;
    top: -16px;
    left: 0;

    display: block;
    width: 100%;
    height: 20px;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--thm-white);
  }
}

.logo_wrap {
  position: relative;
  display: block;
}

.logo {
  bottom: 0;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  z-index: 1;
  flex: 1;
}

// Views
.root_desktop {
  .subheader {
    gap: 12px;
    min-height: 76px;
  }

  .logo_wrap,
  .logo,
  .logo > div {
    min-width: 128px;
    width: 128px;
  }

  .logo,
  .logo > div {
    min-height: 128px;
    height: 128px;
  }
}

.root_tablet {
  .subheader {
    gap: 16px;
  }

  .logo_wrap,
  .logo,
  .logo > div {
    min-width: 120px;
    width: 120px;
  }

  .logo,
  .logo > div {
    min-height: 120px;
    height: 120px;
  }
}

.root_phone {
  .subheader {
    gap: 16px;
    padding: 0 16px 16px;
    flex-direction: column;
  }

  .logo_wrap {
    margin-top: -70px;
  }

  .logo_wrap,
  .logo,
  .logo > div {
    min-width: 100px;
    width: 100px;
  }

  .logo_wrap,
  .logo,
  .logo > div {
    min-height: 100px;
    height: 100px;
  }
}
