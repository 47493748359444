$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 16px;
}

.row {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > div {
    &:last-child {
      pointer-events: none;
      user-select: none;
    }
  }
}

.label {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
  margin-bottom: 4px;
}

.subtitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.toggle_btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
