.root {
  --minheight: 48px;
  --btn-w: 48px;
  --icon-size: 24px;
  --text-fs: 17px;
  --text-lh: 22px;
  --text-ls: -0.408px;

  position: relative;
  display: flex;

  background: var(--thm-white);
  box-shadow: 0 0 0 1px var(--thm-grey-1);
  border-radius: 12px;
  transition: 0.2s ease;

  min-height: var(--minheight);
  padding: 8px calc(var(--btn-w) + 10px) 8px 12px;
  overflow: hidden;
  width: 100%;
}

.root_small {
  --minheight: 36px;
  --btn-w: 44px;
  --icon-size: 20px;
  --text-fs: 15px;
  --text-lh: 20px;
  --text-ls: -0.24px;
}

.root {
  background: var(--thm-grey-1);
  border: 1px solid var(--thm-grey-2);

  textarea {
    font-size: var(--text-fs);
    line-height: var(--text-lh);
    letter-spacing: var(--text-ls);
    color: var(--thm-black);

    caret-color: var(--clr-blue);

    &::placeholder {
      color: var(--thm-silver-grey);
      transition: 0.2s ease;
    }
  }

  &:hover {
    &:not(.root_filled) {
      .btn:not(.btn_editing) svg {
        color: var(--thm-black);
      }
    }

    textarea::placeholder {
      color: var(--thm-black);
    }
  }

  &_editing {
    background: var(--thm-grey-1);

    textarea::placeholder {
      display: none;
      visibility: hidden;
    }
  }
}

.root_rounded {
  border-radius: 18px;
}

.open_btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.btn {
  position: absolute;
  top: 0;
  right: 0;

  width: var(--btn-w);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s ease;

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    color: var(--clr-blue);
    transition: 0.2s ease;
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 1px;
    height: 80%;
    background-color: var(--thm-silver-grey-2);
    transition: 0.2s ease;
  }

  &:disabled {
    svg {
      color: var(--thm-silver-grey);
      opacity: 0.5;
    }
  }
}

.root:not(.root_loading) {
  .btn.btn_editing:not(.btn_editing:disabled) {
    &:hover {
      background: var(--clr-blue);

      &:before {
        background: var(--clr-blue);
      }

      svg {
        color: var(--clr-white);
      }
    }
  }
}

.btn_default {
  &:hover {
    background: var(--thm-silver-grey-2);
    svg {
      color: var(--thm-black);
    }
  }
}

.root_filled:hover {
  .btn_default {
    z-index: 1;

    svg {
      &:first-child {
        display: none;
      }

      &:last-child:not(:first-child) {
        display: block;
      }
    }
  }
}

.root_filled {
  textarea {
    opacity: 1;

    &::placeholder {
      color: var(--thm-black);
      -webkit-text-fill-color: var(--thm-black);
    }
  }
}
