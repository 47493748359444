$desktop: 1300px;
$desktop-sm: 1024px;

.root {
  display: flex;
  gap: 8px;

  overflow: visible;
  position: absolute;
  bottom: 0;
  width: auto;

  @media (max-width: $desktop) {
    gap: 4px;
  }
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 8px 8px;

  text-align: left;
  border-radius: 8px;
  background: var(--thm-grey-1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--thm-silver-grey-2);
    border-radius: 8px;
    transition: border 0.2s ease;
  }

  > img {
    height: 28px;
    width: auto;

    @media (max-width: $desktop) {
      height: 20px;
    }
  }

  > div {
    @media (max-width: $desktop) {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &:not(.btn_blue):not(.btn_purple):not(.btn_green):hover::before {
    border: 1.5px solid var(--thm-silver-grey-1);
  }
}

.title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  display: block;
}

.subtitle {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.btn_blue {
  background: var(--clr-blue-15);
  &::before {
    border: 1px solid var(--clr-blue-15);
  }

  &:hover::before {
    border: 1.5px solid var(--clr-blue);
  }

  .subtitle {
    color: var(--clr-blue);
  }
}

.btn_green {
  background: var(--clr-green-15);
  &::before {
    border: 1px solid var(--clr-green-15);
  }

  &:hover::before {
    border: 1.5px solid var(--clr-green);
  }

  .subtitle {
    color: var(--clr-green);
  }
}

.btn_purple {
  background: var(--clr-purple-15);
  &::before {
    border: 1px solid var(--clr-purple-15);
  }

  &:hover::before {
    border: 1.5px solid var(--clr-purple);
  }

  .subtitle {
    color: var(--clr-purple);
  }
}

.add_icon {
  display: none;
  align-items: center;
}

.filler {
  min-width: 120px;
  height: 36px;

  @media (min-width: #{$desktop-sm + 1}) {
    display: none;
  }
}

.li {
  flex-shrink: 0;

  &:not(.li_visible) {
    opacity: 0;
    position: absolute;

    .subtitle {
      @media (max-width: $desktop) {
        display: none;
      }
    }

    .add_icon {
      @media (max-width: $desktop) {
        display: flex;
      }
    }
  }
}

.root_hovered .li {
  opacity: 1;
  position: relative;
}
