$duration: 0.6s;
$stroke-offset: 187;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 24px 16px;
}

.spinner {
  transform-origin: center;
  transition-property: transform;
  animation-name: rotator;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: calc(var(--size) / 10) solid var(--color);
  border-top: calc(var(--size) / 10) solid transparent;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
