$tab: 770px;

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.35px;
  margin-bottom: 16px;
}

.subtitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;
  margin-bottom: 8px;
}

.header {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;

  @media (max-width: $tab) {
    flex-direction: column;
  }
}

.prices_wrap {
  display: flex;
  align-items: center;

  background: var(--thm-grey-1);
  border-radius: 12px;

  .price:not(:last-child) {
    &:after {
      content: '';
      position: absolute;

      height: 65%;
      width: 1px;
      right: 0;

      background-color: var(--thm-silver-grey-2);
    }
  }
}

.price {
  position: relative;

  padding: 0 12px;
  min-width: 142px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  white-space: nowrap;

  b {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.41px;
  }

  small {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: var(--thm-silver-grey);
  }
}

.fields {
  display: flex;
  gap: 8px;
  flex: 1;
}

.field {
  flex: 1;
  position: relative;

  &:last-child {
    @media (min-width: #{$tab + 1}) {
      max-width: 120px;
    }
  }
}

.promolist > li:not(:last-child) {
  border-bottom: 1px solid var(--thm-grey-1);
  padding-bottom: 12px;
  margin-bottom: 12px;
}
