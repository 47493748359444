.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: -8px;

  > li {
    margin-top: 8px;
    margin-left: 8px;
  }
}
