$desktop: 1024px;
$tab: 770px;

.root {
  background: var(--thm-white);
  border-top: 1px solid var(--thm-grey-1);

  z-index: 100;
  position: sticky;
  bottom: 0;
  left: 0;

  padding-bottom: env(safe-area-inset-bottom);

  @media (min-width: #{$desktop + 1}) {
    display: none;
  }
}

.list {
  display: flex;

  > li {
    flex: 1;
  }
}

.list_link {
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;

  color: var(--thm-silver-grey);
  transition: color 0.2s ease;

  @media (min-width: #{$tab + 1}) {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 4px 0;
  }
}

.list_link_active {
  color: var(--thm-black);
}

.icon {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    width: 28px;
    height: 28px;
  }
}

.label {
  font-size: 10px;
  font-weight: 500;
  line-height: 11.93px;
  letter-spacing: -0.24px;
  text-align: center;

  @media (min-width: #{$tab + 1}) {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.41px;
  }
}

.count {
  position: absolute;
  top: -4px;
  right: -4px;

  border-radius: 14px;
  background: var(--clr-primary);

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 19px;
  height: 19px;
  padding: 0 6px;

  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.066px;
  color: var(--clr-white);
}
