body {
  --clr-green: #36b555;
  --clr-blue: #007aff;
  --clr-primary: #e60000;
  --clr-purple: #8e2cbc;
  --clr-green-15: rgba(52, 199, 89, 0.15);
  --clr-purple-15: rgba(142, 44, 188, 0.15);
  --clr-blue-15: rgba(0, 122, 255, 0.15);
  --clr-blue-30: rgba(0, 122, 255, 0.3);
  --clr-onbubble: #b2d7ff;
  --clr-red-15: rgba(230, 0, 0, 0.15);
  --clr-white: #ffffff;
  --clr-black: #222222;
  --clr-grey: #a6a6a6;
  --clr-orange: #ff993c;
  --clr-orange-15: rgba(255, 153, 60, 0.15);
  --shadow-primary: 0px 4px 28px 0px #00000014;

  --thm-card: #fff;
  --thm-white: #fff;
  --thm-white-bg: #fff;
  --thm-black: #272727;
  --thm-blue: var(--clr-blue);
  --thm-blue-15: var(--clr-blue-15);
  --thm-grey-1: #f2f2f7;
  --thm-grey-2: #ececec;
  --thm-grey-3: #a6a6a6;
  --thm-grey-4: #656565;
  --thm-silver-grey: #818c99;
  --thm-silver-grey-1: #ccd1d7;
  --thm-silver-grey-2: #e3e3ea;
  --thm-photo-stroke: rgba(0, 0, 0, 0.06);
  --thm-light-green: #e1fec6;
  --thm-bg-90: rgba(255, 255, 255, 0.9);
  --thm-bg: #f2f2f7;
  --thm-gold: #877200;
  --thm-gold-10: rgba(135, 114, 0, 0.1);
  --thm-light-gold: #fff4bc;

  --chat-bg-color: #d8e2ed;
  --chat-bg-opacity: 0.3;
}

body.theme-dark {
  --thm-card: #282828;
  --thm-white: #1e1e1e;
  --thm-white-bg: #111111;
  --thm-black: #fff;
  --thm-blue: #fff;
  --thm-blue-15: #2f2f2f;
  --thm-grey-1: #2f2f2f;
  --thm-grey-2: #575757;
  --thm-grey-3: #5e5e5e;
  --thm-silver-grey: #969696;
  --thm-silver-grey-1: #848484;
  --thm-silver-grey-2: #3d3d3d;
  --thm-photo-stroke: rgba(255, 255, 255, 0.06);
  --thm-light-green: #2f3b20;
  --thm-bg-90: rgba(0, 0, 0, 0.9);
  --thm-bg: #111111;
  --thm-gold: #ffeb80;
  --thm-gold-10: rgba(255, 235, 128, 0.1);
  --thm-light-gold: #5d5943;

  --chat-bg-color: #13161a;
  --chat-bg-opacity: 0.7;
}
