$tab: 770px;

.root {
  position: relative;
  z-index: 1;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 60%;
  }
}

.snapshot {
  border-radius: 15px;
  object-fit: cover;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.open_btn,
.snapshot,
.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
