.root {
  position: relative;
}

.attach_btn {
  min-width: 28px;
  width: 28px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--thm-silver-grey);

  > svg {
    width: 28px;
    height: 28px;
  }
}

.attach_btn + div {
  right: unset !important;
  left: 0;
}

.dropdown_btn {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
}
