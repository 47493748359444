.container {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &_small {
    gap: 4px;
  }
}

.input {
  display: none;
}

.text {
  cursor: pointer;

  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
}

.label {
  position: relative;

  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg {
    transition: 0.15s ease;

    width: 24px;
    height: 24px;
    color: var(--checkbox-default-color);
  }
}

.container_small {
  .text {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    padding-top: 0.05em;
  }

  .label {
    min-width: 20px;

    &,
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

input:checked + .label svg,
.container:hover .label svg {
  color: var(--checkbox-checked-color);
}

input:disabled:not(:checked) {
  & ~ .text {
    color: var(--thm-silver-grey);
  }

  & + .label svg {
    color: var(--thm-silver-grey-1);

    path:first-child {
      fill: var(--thm-grey-2);
    }
  }
}
