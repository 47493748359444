.root {
  position: relative;
  padding: 16px 80px 16px 16px;

  border-radius: 12px;
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-white);
  box-shadow: var(--shadow-primary);
}

.title {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.41px;
  margin-bottom: 4px;
}

.text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);
}

.switch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  pointer-events: none;
}

.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
