.root {
  position: relative;

  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 6px;
  margin: 4px 6px 4px 12px;

  &::before {
    content: '';
    width: 2px;
    height: calc(100% - 4px);

    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    background: var(--replied-color);
    border-radius: 1px;
  }
}

.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.img {
  display: block;
  min-width: 28px;
  width: 28px;
  height: 28px;

  border-radius: 4px;
  object-fit: cover;
}

.img + .wrap {
  max-width: calc(100% - 32px);
}

.name {
  font-weight: 600;
  color: var(--replied-color);
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name,
.text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.wrap {
  max-width: 100%;
}
