$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 12px;
}

.desc {
  color: var(--thm-silver-grey);
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  margin-bottom: 16px;

  &_red {
    color: var(--clr-primary);
  }
}
