.banner_black {
  color: var(--thm-white);
  background: var(--thm-black);
}

.banner_red {
  color: var(--clr-primary);
  background: var(--clr-red-15);
}

.banner,
.topup_btn,
.close_btn {
  display: flex;
  align-items: center;
}

.banner {
  border: 1px solid var(--thm-photo-stroke);
  gap: 12px;
  padding: 12px 16px;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;
  }
}

.topup_btn {
  svg {
    width: 12px;
    height: 12px;
  }
}

.topup_btn,
.close_btn {
  gap: 2px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: left;
}

.close_btn {
  margin-left: auto;

  svg {
    width: 16px;
    height: 16px;
  }
}
