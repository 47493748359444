$mob: 770px;

.placeholder {
  min-height: calc(100vh - var(--header-h) - 150px);
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - 150px);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mob) {
    min-height: calc(100vh - var(--header-h) - var(--navbar-h) - 150px);
    min-height: calc(
      var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h) - 150px
    );
  }
}

.search {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  > div:first-child {
    flex: 1;
  }

  button {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.msg {
  margin-bottom: 24px;

  &:empty {
    display: none;
  }
}
