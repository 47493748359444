$tab: 770px;

.modal_container {
  padding: 16px 0 !important;
}

.btn {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 204px;
  padding: 6px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  white-space: nowrap;

  @media (max-width: $tab) {
    padding: 6px 12px;
  }

  img {
    width: 32px;
    height: 32px;
  }
}

.hr {
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background: var(--thm-grey-1);
}

.label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--thm-silver-grey);
  text-transform: uppercase;

  padding-left: 8px;
  margin-bottom: 4px;

  @media (max-width: $tab) {
    padding-left: 12px;
  }
}

.li_first {
  padding-top: 4px;
}
