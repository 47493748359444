$desktop-sm: 1072px;
$mob: 770px;

.dropdown_modal {
  @media (max-width: $mob) {
    height: calc(100% - 32px);
  }
}

.root {
  position: relative;

  border-radius: 8px;
  background: var(--thm-card);
  box-shadow: 0 0 0 1px var(--thm-grey-1) inset;
  transition: 0.2s ease;

  &:not(.root_disabled) {
    &:hover {
      box-shadow: 0 0 0 1px var(--clr-blue) inset;
    }
  }

  &_disabled {
    background: var(--thm-grey-1);

    .placeholder,
    .select_btn {
      color: var(--thm-grey-3);
    }
  }
}

.root_large {
  border-radius: 12px;

  .select_btn {
    font-size: 17px;
    letter-spacing: -0.408px;
    line-height: 22px;

    padding: 12px;
    min-height: 56px;
  }
}

.root:not(.root_disabled) .btn_right svg,
.placeholder {
  color: var(--thm-silver-grey);
}

.select_btn {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  width: 100%;
  min-height: 36px;
  padding: 8px 8px 8px 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn_right svg {
    min-width: 20px;
    width: 20px;
    height: 20px;

    transition: 0.2s ease;
    transform-origin: 50% 50%;
  }
}

.btn_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn_right {
  display: flex;
  align-items: center;
  gap: 8px;
}

// List
.list_icon {
  color: var(--clr-blue);
}

.clear_icon {
  color: var(--thm-silver-grey);
}

.check_icon {
  border: 1px solid var(--thm-silver-grey-1);
  border-radius: 50%;
  transition: 0.2s ease;

  svg {
    opacity: 0;
    transition: 0.2s ease;
  }

  &[data-active='true'] {
    border: 1px solid rgba(0, 0, 0, 0);

    svg {
      opacity: 1;
    }
  }
}

.list_icon,
.clear_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;

  &,
  svg {
    width: 20px;
    height: 20px;
  }
}

.seperated_li {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--thm-grey-1);
}

.list_btn {
  display: flex;
  align-items: center;
  gap: 12px;

  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.group_title {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  padding: 0px;

  @media (max-width: $desktop-sm) {
    padding: 0px 8px;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.dropdown li[data-active='true']:not(:hover) > * {
  background: 0 !important;
}

.li {
  margin-bottom: 8px;

  @media (max-width: $desktop-sm) {
    margin: 8px 0;
    padding: 0 12px;
  }

  > div {
    padding: 0;
    background: 0 !important;
  }
}

// Opened
.root_open {
  .select_btn .btn_right svg {
    transform: rotate(180deg);
  }
}

.dropdown {
  @media (min-width: #{$mob + 1}) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
