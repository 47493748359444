body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: var(--thm-black);
}

h1,
.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33;
}

h2,
.h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.33;
}

p {
  font-size: 17px;
  line-height: 1.29;
}

.country-emoji {
  font-family: 'Noto Color Emoji', -apple-system, BlinkMacSystemFont, sans-serif;
  margin-right: 0.3em;
}
