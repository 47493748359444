.root {
  border-top: 1px solid var(--thm-grey-1);
  background: var(--thm-white);
  box-shadow: var(--shadow-primary);
  transition: opacity 0.2s ease;

  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 0;

  &_hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.img {
  position: relative;
  width: 74px;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75.22%;
  }
}

.img_vrp_inner,
.img_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: contain;
  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);
  background-color: var(--thm-card);
}

.img_vrp_inner {
  padding: 0 4px;
}

.name {
  font-size: 17px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.408px;
  margin-bottom: 4px;
}

.info {
  color: var(--thm-silver-grey);
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}
