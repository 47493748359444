$desktop: 1432px;
$desktop-sm: 1072px;
$desktop-s: 1024px;
$tab: 770px;
$mob: 520px;

.root {
  margin-top: -24px;

  min-height: calc(100vh - var(--header-h));
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h));

  @media (max-width: $tab) {
    min-height: calc(100vh - var(--header-h) - var(--navbar-h));
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h));
  }
}

.box {
  position: relative;
  z-index: 4;

  margin-bottom: 36px;
  padding: 24px;
}

.additional_fields > div > div {
  padding-top: 16px;
}

.fields {
  display: flex;
  flex-wrap: wrap;

  margin-left: -12px;
  margin-top: -12px;

  > li {
    margin-top: 12px;
    margin-left: 12px;
    width: calc(33.33% - 12px);

    &.fields_fit {
      width: calc(100% - 12px);
    }

    @media (max-width: $tab) {
      width: 100%;
    }
  }

  @media (max-width: $tab) {
    width: 100%;
  }
}

.row {
  display: flex;
  margin-left: -12px;

  > div {
    margin-left: 12px;
    width: calc(50% - 12px);
  }
}

.row_group {
  display: flex;

  > div {
    width: 50%;

    input:hover,
    input:focus {
      position: relative;
      z-index: 1;
    }

    &:first-child {
      &,
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      &,
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  height: 100%;

  label:last-child {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    transform: translateY(1px);
  }
}

.tabs {
  @media (min-width: #{$desktop-sm + 1}) {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: $desktop-sm) {
    margin-bottom: 12px;
  }
}

.specs_list {
  > li {
    &:not(:last-child):not(.specs_autofilter) {
      margin-bottom: 12px;
    }
  }
}

.specs_autofilter {
  margin-bottom: 24px;
}

.search_field {
  flex: 1;
  width: 100%;
  max-width: 240px;
  margin-right: auto;
  margin-bottom: 16px;
  padding-left: 16px;
}

.statuses {
  z-index: 100;
  position: sticky;
  top: var(--header-h);

  width: calc(100% + var(--main-content-padding-x) * 2);
  margin-left: calc(-1 * var(--main-content-padding-x));
  margin-bottom: 24px;
  padding: 16px var(--main-content-padding-x) 0;
  background: var(--thm-white);
  overflow-x: auto;

  &::before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
    background: var(--thm-grey-1);
  }
}

.skeletons {
  display: flex;
  gap: 24px;
}

.container {
  max-width: 1600px;
}
