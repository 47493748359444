$tab: 770px;

div.modal_container {
  padding: 24px;
}

.root {
  @media (max-width: $tab) {
    padding-top: 24px;
  }
}

.topup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  padding: 4px 12px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  color: var(--thm-silver-grey);
}
