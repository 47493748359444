$mob: 770px;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: $mob) {
    margin-bottom: 24px;
  }
}

.text {
  padding-right: 8px;

  @media (max-width: $mob) {
    padding-right: 24px;
  }
}

.img {
  min-width: 56px;
  width: 56px;
  height: auto;

  @media (max-width: $mob) {
    min-width: 36px;
    width: 36px;
  }
}

.specs {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;

  > li {
    &,
    &::after {
      display: inline-block;
      vertical-align: middle;
    }

    &:not(:last-child)::after {
      content: '';

      width: 4px;
      height: 4px;
      margin: -0.1em 8px 0;

      background: var(--thm-silver-grey-1);
      border-radius: 50%;
    }
  }
}
