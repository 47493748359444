$mob: 770px;

.placeholder {
  min-height: calc(100vh - var(--header-h) - 150px);
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - 150px);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mob) {
    min-height: calc(100vh - var(--header-h) - var(--navbar-h) - 150px);
    min-height: calc(
      var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h) - 150px
    );
  }
}

.container {
  max-width: 1600px;
}
