.border {
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border-radius: 100%;

  width: 100%;
  height: 100%;
}

.avatar {
  position: relative;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.avatar_btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  span {
    display: none;
  }
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;
}

.placeholder {
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  user-select: none;

  letter-spacing: 0.5px;
  color: var(--clr-white);
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--clr-blue-15);
}
