$tab: 770px;

div.modal_container {
  @media (min-width: #{$tab + 1}) {
    width: 400px;
  }
}

.buttons {
  display: flex;
  margin-top: 24px;
  gap: 8px;

  button {
    flex: 1;
  }
}
