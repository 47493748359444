$desktop-lg: 1300px;
$desktop: 1024px;

.root {
  position: relative;

  transition: 0.2s ease;
  border-radius: 16px;
  background: var(--thm-white);
  border: 1px solid var(--thm-grey-1);

  padding: 16px;

  &,
  p {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.408px;
  }
}

.root:hover {
  background: var(--thm-card);
  box-shadow: var(--shadow-primary);

  .actions {
    @media (min-width: #{$desktop-lg + 1}) {
      opacity: 1;
    }
  }

  .work_time {
    @media (min-width: #{$desktop-lg + 1}) {
      opacity: 0;
    }
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div {
    flex: 1;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  position: relative;
}

.person {
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1;

  max-width: 100%;
  @media (min-width: #{$desktop + 1}) {
    max-width: calc(100% - 180px);
  }
}

.name {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: calc(100% - 60px);

  h2 {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.41px;
    text-align: left;

    color: var(--thm-black);

    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;

    color: var(--thm-silver-grey);
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: #{$desktop-lg + 1}) {
    opacity: 0;
    transition: 0.2s all ease;

    position: absolute;
    right: 0;
    top: 0;
  }

  &_desktop {
    @media (max-width: $desktop) {
      display: none;
    }
  }

  &_mobile {
    @media (min-width: #{$desktop + 1}) {
      display: none;
    }
  }
}

.work_time {
  display: flex;
  flex-direction: column;
  gap: 2px;

  white-space: nowrap;

  &_desktop {
    opacity: 1;
    transition: 0.1s all ease;

    padding-left: 12px;
    border-left: 1px solid var(--thm-grey-1);

    @media (max-width: $desktop-lg) {
      display: none;
    }
  }

  &_mobile {
    @media (min-width: #{$desktop-lg + 1}) {
      display: none;
    }
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;

    color: var(--thm-silver-grey);
  }

  b {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-align: left;

    color: var(--thm-black);
  }
}

.info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: $desktop) {
    flex-wrap: wrap;
  }
}

.langs {
  min-height: 44px;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;

    color: var(--thm-silver-grey);
  }

  b {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-align: left;

    color: var(--thm-black);
  }
}

.phone {
  display: flex;
  align-items: center;
  gap: 8px;

  flex-shrink: 0;

  @media (max-width: $desktop) {
    width: 100%;
  }

  b {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.38px;
    text-align: right;
  }

  svg,
  b {
    color: var(--clr-blue);
  }
}

.delete {
  button {
    width: 36px;
  }
}
