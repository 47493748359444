.root {
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: stretch;

  > * {
    flex: 1;
    background-color: var(--thm-grey-1);
    border-radius: 8px;

    overflow: hidden;
    // min-height: 220px;
    padding: 12px 16px 16px 16px;
  }
}

.graph {
  width: 100%;
  height: 100%;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
