.modal_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.field {
  position: relative;
  margin-bottom: 12px;
}

.map {
  margin: 16px 0 24px;
  height: 240px;
  border-radius: 12px;
  overflow: hidden;
  transform: translateZ(0);

  > div > div:not(:last-child) {
    display: none;
    user-select: none;
    pointer-events: none;
    z-index: -999;
  }
}

.label {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;

  margin-bottom: 12px;
}

.hours {
  display: inline-flex;
  margin-bottom: 24px;

  border-radius: 12px;
  border: 1px solid var(--thm-grey-2);

  > div {
    min-width: 140px;
    border: 1px solid rgba(0, 0, 0, 0);

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid var(--thm-grey-2);
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.add_address {
  margin-top: auto;
}
