$tab: 770px;

.root {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--thm-grey-1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
}

.author {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
}

.name {
  font-size: 17px;
  font-weight: 500;
  line-height: 23.8px;
  letter-spacing: -0.4px;
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.review_info {
  overflow: hidden;
}

.date {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.more_btn {
  color: var(--thm-silver-grey-1);
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: 24px;
    height: 24px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;

  margin-left: 12px;
  padding-left: 12px;
}

.stars_wrap {
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: $tab) {
    flex-direction: column;
    align-items: stretch;
  }
}

.stars {
  display: flex;
}

.star {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-silver-grey-2);

  &,
  svg {
    width: 16px;
    height: 16px;
  }
}

.star_fill {
  color: var(--clr-orange);
}

.deal_status,
.advert_name,
.date,
.text,
.toggle {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;

  @media (min-width: #{$tab + 1}) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
}

.toggle svg {
  @media (min-width: #{$tab + 1}) {
    width: 20px;
    height: 20px;
  }
}

.toggle {
  display: flex;
  align-items: center;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--clr-blue);

  svg {
    transition: 0.2s ease;
  }

  &_opened svg {
    transform: rotate(180deg);
  }
}

.advert_name,
.date {
  color: var(--thm-silver-grey);
}

.deal_status {
  color: var(--clr-primary);
}

.deal_status_green {
  color: var(--clr-green);
}

.date {
  margin-bottom: 4px;
}

.text {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.text_trunc {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-height: 54px;

  @media (min-width: #{$tab + 1}) {
    max-height: 60px;
  }
}

.reply {
  padding-left: 24px;
  margin-top: 12px;

  > div {
    margin-bottom: 4px;
  }
}

.review_params {
  display: flex;
  justify-content: space-between;

  margin-top: 12px;
}

.param_btn {
  display: flex;
  gap: 4px;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;
  }

  p,
  svg {
    color: var(--clr-blue);
  }
}

.danger_btn {
  p,
  svg {
    color: var(--clr-primary);
  }
}

.reply_text,
.create_reply {
  margin-top: 12px;
  padding-left: 36px;
}

.reply_header {
  margin-bottom: 4px;
}

.reply_date {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: left;

  color: var(--thm-silver-grey);
}

.reply_content {
  display: flex;
  justify-content: space-between;
}

.reply_delete {
  margin-left: 12px;
  padding-left: 13px;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    color: var(--thm-silver-grey);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: var(--thm-silver-grey-1);
    transition: 0.2s ease;
  }
}

.status {
  margin-bottom: 12px;

  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.41px;

    @media (min-width: #{$tab + 1}) {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
    }
  }

  color: var(--thm-black);

  &_moderation {
    color: var(--clr-orange);
  }

  &_declined {
    color: var(--clr-primary);
  }
}

.hidden_review {
  opacity: 0.5;
}
