$tab: 770px;

.root {
  display: flex;
  align-items: center;
  gap: 12px;

  padding: 8px 12px 8px 16px;

  border-bottom: 0.5px solid var(--thm-grey-1);
}

.icon,
.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-blue);
}

.icon {
  &,
  svg {
    width: 28px;
    height: 28px;
  }
}

.content {
  flex: 1;
  min-height: 32px;
  padding-left: 6px;

  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: var(--clr-blue);
  }
}

.name,
.text {
  font-size: 14px;
  line-height: 1.33;

  @media (max-width: $tab) {
    font-size: 12px;
  }
}

.name {
  font-weight: 600;
  color: var(--clr-blue);
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close_btn {
  margin-left: auto;
  width: 28px;
  height: 28px;
}

.preview_img_text {
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.preview_img {
  min-width: 20px;
  width: 20px;
  height: 20px;

  object-fit: cover;
  border-radius: 2px;
}
