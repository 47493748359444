$tab: 770px;

.dropdown_modal {
  @media (max-width: $tab) {
    height: calc(100% - 32px);
  }
}

.dropdown {
  position: absolute;
  bottom: 0;
  left: 0;
}

.root {
  position: relative;
  display: flex;
  align-items: center;

  white-space: nowrap;
  font-size: 14px;

  border-radius: 12px;
  background: var(--thm-card);
  transition: 0.2s ease;
}

.root::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;
  border-radius: 12px;
  box-shadow: 0 0 0 1px var(--thm-grey-1) inset;
  transition: border-color 0.2s ease;
}

.root_selected {
  .label {
    transform: translateY(calc(-50% + -8px)) scale(0.67);
  }

  .select_btn svg {
    color: var(--thm-black);
  }
}

.root_disabled {
  background: var(--thm-grey-1);

  &,
  .label,
  .select_btn svg {
    color: var(--thm-grey-3);
  }

  &::before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0) inset;
  }
}

.label {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  line-height: 1;
  color: var(--thm-silver-grey);
  transition: 0.2s ease;
  transform-origin: 0% 0%;
  pointer-events: none;
  user-select: none;
}

.label,
.select_btn {
  font-size: 17px;
  letter-spacing: -0.408px;
}

.select_btn {
  line-height: 22px;

  min-height: 56px;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  transition: 0.2s ease;

  svg {
    margin-left: auto;
    transform-origin: 50% 50%;
    transition: transform 0.2s ease;
    color: var(--thm-grey-3);
  }
}

.root_open {
  &::before {
    box-shadow: 0 0 0 2px var(--clr-blue) inset;
  }

  &.root_labeled {
    .select_btn {
      .chevron {
        transform: rotate(180deg) translateY(6px);
      }
    }
  }
}

.root_open:not(.root_labeled) {
  .select_btn {
    .chevron {
      transform: rotate(180deg);
    }
  }
}

.root_labeled {
  .select_btn {
    padding: 26px 12px 8px;

    svg {
      transform: translateY(-6px);
    }
  }
}

.extra {
  margin-left: auto;
  color: var(--thm-silver-grey);
}

.button_name {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_btn {
  display: flex;
  align-items: center;

  border-radius: 8px;
}

.list_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &_fullwidth {
    max-width: 80%;
  }
}

.li {
  margin-bottom: 8px;

  @media (max-width: $tab) {
    margin: 8px 0;
    padding: 0 12px;
  }

  > div {
    background: 0 !important;

    @media (min-width: #{$tab + 1}) {
      padding: 0;
    }
  }
}

// Range
.range_dropdown > ul {
  padding: 4px !important;
  max-height: unset !important;
  overflow: auto !important;
}

.range_wrap {
  display: flex;
}

.range_list_wrap {
  max-height: 296px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: $tab) {
    flex: 1;
  }

  &:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid var(--thm-grey-1);
  }
}

.range_wrap_fullwidth {
  .range_list_wrap {
    flex: 1;
  }
}

.range_list {
  .list_btn {
    min-width: 193px;
    padding: 8px 12px 8px 8px;
    gap: 8px;

    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.408px;

    @media (max-width: $tab) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .list_check {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-blue);
  }
}

// Error
.root_error::before {
  box-shadow: 0 0 0 2px var(--clr-primary) inset;
}

// Active
.root_active {
  background-color: var(--clr-blue-15);

  &::before {
    box-shadow: 0 0 0 2px var(--clr-blue-15) inset;
  }
}

// Size
.root_small {
  &,
  &::before {
    border-radius: 8px;
  }

  .select_btn {
    min-height: 36px;
    padding: 8px 8px 8px 12px;
  }

  &.root_error::before {
    box-shadow: 0 0 0 1px var(--clr-primary) inset;
  }

  &.root_open {
    &::before {
      box-shadow: 0 0 0 1px var(--clr-blue) inset;
    }
  }

  &.root_active {
    &::before {
      box-shadow: 0 0 0 1px var(--clr-blue-15) inset;
    }
  }
}

.empty {
  width: 100%;
  text-align: center;

  color: var(--thm-grey-3);
}
