$desktop-sm: 1072px;
$tab: 770px;
$mob: 500px;

.root {
  position: relative;

  border-radius: 8px;
  background: var(--thm-white);
  border: 1px solid var(--thm-grey-2);
  transition: 0.2s ease;

  &:not(.root_disabled) {
    &:hover,
    &.root_selected {
      border: 1px solid var(--clr-blue-30);
    }
  }

  &_selected {
    background: var(--clr-blue-15);
  }

  &_disabled {
    background: var(--thm-grey-1);

    .placeholder,
    .select_btn {
      color: var(--thm-grey-3);
    }
  }
}

.root:not(.root_selected):not(.root_disabled) .select_btn .btn_right svg,
.placeholder {
  color: var(--thm-silver-grey);
}

.select_btn {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  width: 100%;
  padding: 6px 12px;
  gap: 8px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn_right svg {
    min-width: 24px;
    width: 24px;
    height: 24px;

    transition: 0.2s ease;
    transform-origin: 50% 50%;
  }
}

.btn_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
}

.btn_right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.clear_icon {
  color: var(--thm-silver-grey);
}

.clear_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;

  &,
  svg {
    width: 20px;
    height: 20px;
  }
}

.seperated_li {
  width: 100%;
}

.list_btn {
  display: flex;
  align-items: center;
  gap: 12px;

  white-space: nowrap;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.gen_btn {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  padding: 14px;

  position: relative;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  font-weight: 400;
  color: var(--clr-white);

  &_height {
    padding-top: 70%;
  }

  span {
    display: block;
    z-index: 3;
  }

  &:before {
    content: '';
    z-index: 2;
    left: 0;
    bottom: 50%;
    position: absolute;

    width: 100%;
    height: 50%;

    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:hover {
    background: unset !important;

    @media (min-width: #{$desktop-sm + 1}) {
      .gen_btn_img {
        transform: scale(1.03);
      }
    }
  }
}

.gen_btn_img {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  transition: 0.2s all ease-out;
}

.overlay_btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.dropdown li[data-active='true']:not(:hover) > * {
  background: 0 !important;
}

.dropdown {
  @media (min-width: #{$desktop-sm + 1}) {
    width: 740px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    max-height: 428px !important;

    @media (max-width: $desktop-sm) {
      max-height: unset !important;
      padding: 8px;
    }
  }

  li:not(.seperated_li) {
    border-radius: 8px;
    overflow: hidden;

    width: calc(33.33% - 6px);

    @media (max-width: $tab) {
      width: calc(50% - 4px);
    }

    @media (max-width: $mob) {
      width: 100%;
    }
  }
}

.li {
  margin-bottom: 8px;

  > div {
    padding: 0;
    background: 0 !important;
  }
}

// Opened
.root_open {
  .select_btn .btn_right svg {
    transform: rotate(180deg);
  }
}
