$tab: 770px;

.modal_root.modal_root_imp {
  @media (min-width: #{$tab + 1}) {
    overflow-y: auto;

    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal_backdrop.modal_backdrop_imp {
  @media (min-width: #{$tab + 1}) {
    position: fixed;
  }
}

.modal_container.modal_container_imp {
  @media (min-width: #{$tab + 1}) {
    overflow: unset;
    max-height: unset;
  }
}

.root {
  @media (min-width: #{$tab + 1}) {
    width: 452px;
  }
}

.hidden {
  opacity: 0 !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}

.title_wrap {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    display: flex;
    align-items: center;
  }
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: 28px;
    height: 28px;
  }

  @media (max-width: $tab) {
    display: none;
  }
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;

  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
  margin: 24px 0 4px;
}

.text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
  margin-bottom: 16px;
}

.dropdown {
  background: var(--thm-card) !important;
}

.field {
  position: relative;
  margin-bottom: 12px;

  textarea {
    padding-bottom: 28px;
  }
}

.action {
  margin-top: 24px;
}

.done {
  text-align: center;

  img {
    display: block;
    width: 340px;
    height: auto;
    margin: 0 auto 16px;
  }

  .title {
    margin-bottom: 12px;
  }

  .text {
    margin-bottom: 24px;
  }
}

.counter {
  position: absolute;
  bottom: 12px;
  right: 12px;

  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.subtitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: left;

  color: var(--thm-silver-grey);

  padding: 4px 0;

  margin-bottom: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    display: flex;
    align-items: center;

    width: 100%;

    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 0 0 1px var(--thm-grey-1);

    background-color: var(--thm-card);
    transition: 0.2s all ease;

    svg {
      margin-left: auto;
    }

    &:hover {
      background-color: var(--thm-grey-1);
    }
  }
}

.delete_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  width: 100%;

  margin-top: 12px;
}

.delete_check {
  position: relative;

  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;

    color: var(--thm-black);
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.spinner {
  width: 100%;

  display: flex;
  justify-content: center;

  margin-top: 24px;
}

.warn_message {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
  text-align: left;

  color: var(--thm-black);
}
