.root {
  width: 100%;
}

.root_small {
  .input {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    padding: 8px 8px 8px 12px;
    min-height: 36px;
  }

  .input,
  .border {
    border-radius: 8px;
  }
}

.label,
.input {
  font-size: 17px;
  letter-spacing: -0.408px;
}

.label,
.input::placeholder {
  color: var(--thm-silver-grey);
}

.label {
  position: absolute;
  left: 12px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);

  line-height: 1;
  transition: 0.2s ease;
  transform-origin: 0% 0%;
  pointer-events: none;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  max-width: 100%;
  overflow: hidden;
}

.input:focus ~ .label,
.root_filled .label {
  transform: translateY(calc(-50% + -8px)) scale(0.67);
}

.input:disabled ~ .label {
  color: var(--thm-grey-3);
}

.required_symbol {
  color: var(--clr-primary);
  margin-left: 0.3em;
}

.input_wrap {
  position: relative;
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: border-color 0.2s ease;
  pointer-events: none;
  border-radius: 12px;
  border: 1px solid var(--thm-grey-1);
}

.input {
  &:hover:not(:focus):not(:disabled):not(.input_error) ~ .border {
    border: 1px solid var(--clr-blue);
  }

  &:focus ~ .border {
    border: 2px solid var(--clr-blue);
  }
}

.input {
  width: 100%;
  padding: 22px 12px 12px;
  min-height: 56px;
  line-height: 22px;

  transition: 0.2s ease;
  border-radius: 12px;
  background: var(--thm-card);
  -webkit-appearance: none;

  &:disabled {
    background: var(--thm-grey-1);
    color: var(--thm-grey-3);
  }
}

// Autofill
@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}

.input:-webkit-autofill {
  animation-name: onAutoFillStart;
}

.input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

// One char input
.root_onechar {
  width: 45px;

  input {
    padding: 12px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.38px;
  }

  &.root_small {
    width: 36px;

    input {
      min-height: 46px;
      padding: 8px;
    }
  }
}

// Error
.input_error ~ .border {
  border: 2px solid var(--clr-primary);
}

.error_wrap {
  display: block;
  padding-left: 12px;

  text-align: left;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.175s;
}

.error_wrap_active {
  max-height: 25px;
}

.error {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;

  color: var(--clr-primary);

  display: block;
  padding-top: 4px;
}

// Bottom label
.bottom_label_wrap {
  display: block;
  padding: 0 12px;

  text-align: left;
  overflow: hidden;
  max-height: 25px;
}

.bottom_label {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;

  display: block;
  padding-top: 4px;

  color: var(--thm-silver-grey);
}

.bottom_label_right {
  &,
  .bottom_label {
    text-align: right;
  }
}

// Password
.action {
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: 20px;
    height: 20px;
  }

  svg {
    color: var(--thm-silver-grey);
    transition: color 0.2s ease;
  }

  &:hover svg {
    color: var(--thm-black);
  }
}

.action_password {
  svg {
    width: 18px;
    height: 18px;
  }
}

.splitted_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_filled {
  background: var(--clr-blue-15);
}

.input_filled ~ .border {
  border: 1px solid var(--clr-blue-15);
}
