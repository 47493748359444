$tab: 770px;

.root {
  margin-top: 8px;
  border-radius: 16px;
}

.modal_container.modal_container_imp {
  height: calc(100% - 32px);

  @media (max-width: $tab) {
    padding: 24px 12px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.36px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: 32px;
    height: 32px;
  }
}

.field {
  margin-bottom: 24px;

  &,
  > div,
  input {
    width: 100%;
  }
}

.chats {
  position: relative;
}

.list_title {
  background: var(--thm-grey-1);
  padding: 4px 24px;
  border-radius: 4px;

  color: var(--thm-silver-grey);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  text-transform: uppercase;

  @media (max-width: $tab) {
    padding: 4px 12px;
  }
}

.list {
  > li:not(:last-child) {
    margin-bottom: 1px;
  }

  > li > div {
    @media (min-width: #{$tab + 1}) {
      padding: 16px 24px;
    }
  }
}

.observer {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 64px;
  opacity: 0;
  pointer-events: none;
}

.empty {
  color: var(--thm-silver-grey);
  text-align: center;
  padding: 24px;

  @media (max-width: $tab) {
    padding: 24px 12px;
  }
}
