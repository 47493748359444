$mob: 770px;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  display: block;
  margin-top: 12px;

  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.408px;
  color: var(--clr-blue);
}
