.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.cover_btn {
  opacity: 0;
  cursor: pointer;
}

.cover_btn,
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  object-fit: cover;
  border-radius: 16px;
  background: var(--thm-card);
}

.controls {
  z-index: 1;
  position: absolute;
  top: 12px;
  left: 12px;

  display: flex;
  align-items: center;
  gap: 12px;
}

.control_btn {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(20px);
  background-color: #1e1e1ebf;
  border-radius: 50%;

  svg {
    color: var(--clr-white);
  }
}

.duration_wrap {
  z-index: 1;
  position: absolute;
  bottom: 8px;
  left: 0;

  width: 100%;
  padding: 0 12px;
}

.duration {
  position: relative;
  background: rgba(#fff, 0.2);
  border-radius: 8px;
  height: 8px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &_progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background: #fff;
    border-radius: 8px;

    &::before {
      content: '';

      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fff;

      position: absolute;
      top: -4px;
      left: calc(100% - 8px);
    }
  }
}

.controls,
.duration_wrap {
  transition: 0.15s ease;
  opacity: 0;
}

.root_hovered {
  .controls,
  .duration_wrap {
    opacity: 1;
  }
}

.root_onhover:hover {
  .controls,
  .duration_wrap {
    opacity: 1;
  }
}
