$desktop-sm: 1024px;

.root {
  position: relative;
  z-index: 7;

  @media (min-width: #{$desktop-sm + 1}) {
    margin-left: auto;
  }
}
