.root {
  margin-top: calc(var(--main-content-padding-y) * -1);
  margin-left: calc(var(--main-content-padding-x) * -1);
  margin-bottom: var(--main-content-padding-y);
  width: calc(100% + var(--main-content-padding-x) * 2);

  background-color: var(--thm-white);

  border-bottom: 1px solid var(--thm-grey-1);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  padding: 8px 24px;

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;

    color: var(--thm-black);
  }
}
