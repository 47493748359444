.root {
  padding: 0 16px;
  margin-bottom: 24px;
}

.root,
.row {
  display: flex;
  align-items: center;
}

.row {
  gap: 8px;
}

.search_field {
  width: 170px;
  min-width: 170px;
}
