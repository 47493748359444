$tab: 770px;

.list {
  > li:not(:last-child) {
    margin-bottom: 36px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;

  b,
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.38px;
  }

  span {
    color: var(--thm-silver-grey);
  }
}

.category_btn {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 12px 0;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  border-bottom: 1px solid var(--thm-grey-1);

  &_active .icon {
    transform: rotate(90deg);
  }

  @media (max-width: $tab) {
    > * {
      flex: 1;
    }
  }
}

.cell {
  width: calc(33.33% + 12px);
  display: flex;
  align-items: center;
}

.icon {
  color: var(--thm-grey-3);
  transition: 0.2s ease;
  transform-origin: 50% 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12px;
  width: 12px;
  margin-right: 16px;
}

.category_circle {
  min-width: 12px;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
}

.category_total {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4;
  letter-spacing: -0.408px;

  @media (max-width: $tab) {
    margin-left: auto;
  }
}

.adverts {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 8px 20px;
  margin-left: -12px;

  > li {
    flex: 1 340px;
    margin-left: 12px;
  }
}

.advert {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--clr-blue);

  border-radius: 8px;
  transition: 0.2s ease;

  &:hover {
    background: var(--thm-grey-1);
  }

  span {
    color: var(--thm-silver-grey);
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.advert_line {
  flex: 1;
  align-self: flex-end;
  border-bottom: 1px dashed var(--thm-silver-grey-1);
  transform: translateY(-0.3em);
}
