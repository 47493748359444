$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 8px;

  span {
    color: var(--thm-silver-grey);
  }
}

.subtitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
  margin-bottom: 24px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:empty {
    display: none;
  }
}

.categories {
  display: flex;
  gap: 16px;
}

.category {
  flex: 1;

  &:not(:first-child) {
    padding-left: 16px;
    border-left: 1px solid var(--thm-grey-1);
  }
}

.category_name {
  background: var(--thm-grey-1);
  border-radius: 12px;

  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.41px;

  padding: 16px 12px;
  margin-bottom: 16px;
}

.category_list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
