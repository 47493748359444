.root {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.textarea_wrap {
  line-height: 0;
  position: relative;
  flex: 1;
}

.textarea_flat,
.textarea {
  width: 100%;

  font-size: 17px;
  line-height: 1.29;
  letter-spacing: -0.408px;
  color: var(--thm-black);
  -webkit-text-fill-color: var(--thm-black);

  &::placeholder {
    color: var(--thm-silver-grey);
    -webkit-text-fill-color: var(--thm-silver-grey);
  }
}

.textarea_flat {
  resize: none;
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: border-color 0.2s ease;
  pointer-events: none;
  border-radius: 12px;
  border: 1px solid var(--thm-grey-1);
}

.textarea {
  &:disabled ~ .border {
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &:focus ~ .border {
    border: 2px solid var(--clr-blue);
  }

  &:hover:not(:focus):not(:disabled):not(.textarea_error) ~ .border {
    border: 1px solid var(--clr-blue);
  }
}

.textarea {
  resize: none;
  padding: 12px;

  transition: 0.2s ease;
  border-radius: 12px;
  background: var(--thm-card);
  -webkit-appearance: none;

  &:disabled {
    background: var(--thm-grey-1);
  }
}

.textarea_error ~ .border {
  border: 2px solid var(--clr-primary);
}

.error_wrap {
  display: block;
  padding-left: 16px;

  text-align: left;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.175s;

  &:empty {
    display: none;
  }
}

.error_wrap_active {
  max-height: 25px;
}

.error {
  font-size: 14px;
  line-height: 1.2;
  color: var(--clr-primary);

  display: block;
  padding-top: 8px;
}

.suffix {
  position: absolute;
  right: 7px;
  top: 7px;

  svg {
    color: var(--thm-silver-grey);
  }
}

// Bottom label
.bottom_label_wrap {
  display: block;
  padding: 0 12px;

  text-align: left;
  overflow: hidden;
  max-height: 25px;
}

.bottom_label {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;

  display: block;
  padding-top: 4px;

  color: var(--thm-silver-grey);
}

.bottom_label_right {
  &,
  .bottom_label {
    text-align: right;
  }
}
