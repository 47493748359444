$mob: 770px;

.root {
  min-height: calc(100vh - var(--header-h));
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-h));
  padding: 36px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mob) {
    min-height: calc(100vh - var(--header-h) - var(--navbar-h));
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h));
  }
}
