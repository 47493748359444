$desktop-lg: 1300px;

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  > li {
    width: calc(50% - 8px);

    @media (max-width: $desktop-lg) {
      width: 100%;
    }
  }
}
