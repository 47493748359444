.loading {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.switch {
  position: relative;

  border: 1px solid var(--thm-grey-1);
  border-radius: 12px;
  padding: 8px 16px 8px 12px;

  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--thm-card);

  > button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  > div {
    pointer-events: none;
    user-select: none;
  }

  &_text {
    display: flex;
    flex-direction: column;

    b {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.5px;

      color: var(--thm-black);
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.24px;

      color: var(--thm-silver-grey);
    }
  }
}

.setting_row {
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.38px;

    color: var(--thm-black);
  }
}

.term_row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}

.input {
  display: flex;
  flex-direction: column;
}

.term_name {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.408px;

  color: var(--thm-black);
}

.bottom_label,
.term_info {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;

  color: var(--thm-silver-grey);
}

.term_info {
  margin-bottom: 8px;
}

.bottom_label {
  margin-top: 4px;
  padding: 0 12px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--thm-grey-1);
}
