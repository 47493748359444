$mob: 770px;

.list_wrap {
  margin-top: 16px;
}

.list {
  @media (min-width: #{$mob + 1}) {
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    margin-left: -12px;
  }

  > li {
    @media (min-width: #{$mob + 1}) {
      margin-top: 12px;
      margin-left: 12px;
      width: calc(25% - 12px);
    }

    @media (max-width: $mob) {
      position: relative;
    }

    &:not(:last-child)::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;

      width: calc(100% - 36px);
      height: 1px;

      background: var(--thm-grey-1);

      @media (min-width: #{$mob + 1}) {
        display: none;
      }
    }
  }
}

.brand_btn {
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: $mob) {
    padding: 14px 0;
  }

  @media (min-width: #{$mob + 1}) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px 8px;

    border-radius: 16px;
  }

  &,
  & span {
    @media (min-width: #{$mob + 1}) {
      transition: 0.2s ease;
    }
  }

  span {
    font-size: 17px;
    line-height: 1;
    letter-spacing: -0.408px;

    @media (min-width: #{$mob + 1}) {
      text-align: center;
    }
  }

  span,
  svg {
    @media (min-width: #{$mob + 1}) {
      color: var(--thm-silver-grey);
    }
  }

  img {
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
    object-fit: contain;

    @media (min-width: #{$mob + 1}) {
      margin-bottom: 12px;
    }

    @media (max-width: $mob) {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
}

.toggler svg {
  transition: 0.2s ease;
}

.toggler_opened svg {
  transform: rotate(180deg);
}

.list > li:not(.toggler) .brand_btn {
  @media (min-width: #{$mob + 1}) {
    background: var(--thm-grey-1);
  }
}

.list > li.toggler .brand_btn {
  flex-direction: row;
  gap: 4px;

  @media (max-width: $mob) {
    padding-bottom: 0;
  }
}

.list > li .brand_btn {
  &:hover {
    @media (min-width: #{$mob + 1}) {
      background: var(--clr-blue-15);
    }

    span,
    svg {
      @media (min-width: #{$mob + 1}) {
        color: var(--clr-blue);
      }
    }
  }
}

.input_text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);

  display: block;
  padding-top: 4px;
  padding-left: 12px;
}
