.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
  text-align: left;

  color: var(--thm-black);
}

.list {
  > li:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--thm-grey-1);
  }
}

.promo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 36px;
    height: 36px;
  }
}

.title {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.41px;
    text-align: left;

    color: var(--thm-black);
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

    color: var(--thm-silver-grey);
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.price {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.41px;
    text-align: right;

    color: var(--thm-black);
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: right;

    color: var(--thm-silver-grey);

    text-decoration: line-through;
  }
}

.title_wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.options_wrap {
  position: relative;

  > span svg {
    width: 20px;
    height: 20px;
    color: var(--thm-silver-grey);

    transition: 0.2s all ease;

    &:hover {
      color: var(--thm-black);
    }
  }
}

.spinner {
  width: 100%;

  display: flex;
  justify-content: center;
}
