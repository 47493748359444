$desktop-sm: 1024px;

.box {
  background: var(--thm-white);
  box-shadow: var(--shadow-primary);
  border-radius: 16px;
  border: 1px solid var(--thm-grey-1);

  padding: 24px;
  &:not(:last-child) {
    margin-bottom: 24px;
    @media (max-width: $desktop-sm) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: $desktop-sm) {
    padding: 16px;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0.36000001430511475px;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    span {
      color: var(--thm-silver-grey);
    }
  }

  p {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);

    a {
      color: var(--clr-blue);
    }
  }
}
