$mob: 770px;

.root {
  &_square {
    .upload_btn {
      position: relative;

      font-size: 15px;
      line-height: 20px;

      background: var(--clr-blue-15);
      border-radius: 8px;

      transition: 0.2s ease;

      @media (min-width: #{$mob + 1}) {
        letter-spacing: -0.24px;
      }

      @media (max-width: $mob) {
        font-size: 12px;
        line-height: 16px;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 74.69%;

        @media (max-width: $mob) {
          padding-top: 77%;
        }
      }

      &_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 36px;
          height: 36px;

          @media (max-width: $mob) {
            width: 32px;
            height: 32px;
          }
        }
      }

      &:hover {
        opacity: 0.84;
      }
    }

    &.root_secondary {
      background: var(--thm-card);

      &,
      .upload_btn {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .upload_btn {
        border-radius: 50%;
        border: 4px solid var(--thm-white);
        background: var(--clr-blue-15);
      }

      .icon {
        svg {
          width: 48px;
          height: 48px;

          @media (max-width: $mob) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    &.root_drag .upload_btn {
      box-shadow: 0 0 0 1px var(--clr-blue);
    }
  }

  &:not(.root_square) .upload_btn {
    padding: 80px 0;

    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    background: var(--clr-blue-15);
    border-radius: 12px;
    transition: 0.2s ease;

    &:hover {
      opacity: 0.84;
    }

    .icon svg {
      width: 48px;
      height: 48px;
    }
  }

  &:not(.root_square).root_drag .upload_btn {
    border: 1px dashed var(--clr-blue);
  }
}

.upload_btn {
  width: 100%;

  text-align: center;
  color: var(--clr-blue);

  &,
  &_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
