.tabs {
  display: flex;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img,
  svg {
    width: 22px;
    height: 22px;
  }
}

.tabs_primary {
  --tab-brs: 8px;

  .tab {
    width: 100%;

    border: 0.5px solid var(--thm-grey-2);
    transition: 0.2s ease;
    color: var(--thm-silver-grey);

    &:hover:not(:disabled) {
      background: var(--clr-blue);
      border-color: var(--clr-blue);
      color: var(--clr-white);
    }

    &:disabled {
      background: var(--thm-grey-1);
    }
  }

  .tab_active {
    position: relative;
    background: var(--clr-blue-15);
    border-color: var(--clr-blue);
    color: var(--thm-black);
  }

  > li {
    &:not(:last-child) .tab:not(.tab_active) {
      border-right: 0.5px solid rgba(0, 0, 0, 0);
    }

    &:first-child .tab {
      border-radius: var(--tab-brs) 0px 0px var(--tab-brs);
    }

    &:last-child .tab {
      border-radius: 0px var(--tab-brs) var(--tab-brs) 0px;
    }
  }

  .tab_m {
    padding: 8px 12px;

    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    min-height: 38px;
  }

  .tab_l {
    --tab-brs: 12px;
    padding: 12px 16px;

    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}

.tabs_secondary {
  gap: 8px;

  .tab {
    padding: 8px 12px;

    background: var(--thm-grey-1);
    border-radius: 8px;
    transition: 0.2s ease;

    white-space: nowrap;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: var(--thm-black);

    &_active {
      background: var(--clr-primary);
      color: var(--clr-white);
    }

    &:disabled {
      opacity: 0.8;
    }

    &:not(.tab_active) span {
      color: var(--thm-silver-grey);
    }
  }
}

.tabs_stroke {
  .tab {
    padding: 12px 12px 15px;
    position: relative;

    transition: 0.2s ease;

    white-space: nowrap;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);

    &:disabled {
      opacity: 0.8;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;

      width: calc(100% - 24px);
      height: 4px;

      background: var(--clr-blue);
      border-radius: 6.67345px 6.67345px 0px 0px;
      transform-origin: 50% 100%;
      transform: translateX(-50%) scaleY(0);
      transition: 0.2s ease;
    }

    &:hover:not(:disabled) {
      color: var(--clr-blue);
    }

    &_active {
      color: var(--clr-blue);

      &::before {
        transform: translateX(-50%) scaleY(1);
      }
    }
  }
}

.tabs_segment {
  padding: 4px;
  background: var(--thm-bg);
  border: 1px solid var(--thm-grey-1);
  border-radius: 8px;

  > li {
    flex: 1;
  }

  .tab {
    border-radius: 4px;
    transition: 0.2s ease;

    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: center;
    color: var(--thm-silver-grey);

    width: 100%;
    padding: 4px 8px;
    justify-content: center;

    &_active {
      color: var(--thm-black);
      background: var(--thm-card);
      box-shadow: var(--shadow-primary);
    }
  }
}

.tabs_underline {
  gap: 24px;

  .tab {
    gap: 8px !important;
    padding-bottom: 14px;
    position: relative;

    transition: 0.2s ease;

    white-space: nowrap;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    &:disabled {
      opacity: 0.8;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;

      width: 100%;
      height: 2px;

      background: var(--clr-primary);
      border-radius: 2px 2px 0px 0px;
      transform-origin: 50% 100%;
      transform: translateX(-50%) scaleY(0);
      transition: 0.2s ease;
    }

    span {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.41px;
      text-align: center;
      color: var(--thm-black);

      padding: 0 7px;
      min-width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 14px;
      background: var(--thm-grey-1);
      transition: 0.2s ease;
    }

    &:hover:not(:disabled) {
      color: var(--clr-primary);
    }

    &_active {
      color: var(--clr-primary);

      span {
        background: var(--clr-primary);
        color: var(--clr-white);
      }

      &::before {
        transform: translateX(-50%) scaleY(1);
      }
    }
  }
}
