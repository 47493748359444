.root {
  padding: 16px 12px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  a:not(.list_link_disabled) {
    color: var(--thm-black);
  }
}

.root_minimized {
}

.root_list {
  > li:not(:last-child) {
    margin-bottom: 16px;
  }
}

.collapse_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  margin-bottom: 8px;

  svg {
    transition: 0.2s ease all;
    transform: rotate(-90deg);
    color: var(--thm-silver-grey-1);
    width: 12px;
    height: 12px;
  }

  &_opened {
    svg {
      transform: rotate(0deg);
    }
  }
}

.label {
  color: var(--thm-silver-grey);

  display: block;
  padding: 0 4px;

  text-transform: uppercase;

  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.06px;
}

.label_dot {
  width: 100%;
  height: 13px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--thm-grey-1);

  svg {
    width: 4px;
    height: 4px;
  }
}

.counter {
  min-width: 13px;

  padding: 0px 3px;
  margin-left: auto;
  border-radius: 14px;
  background-color: var(--thm-silver-grey);

  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.065px;

  color: var(--clr-white);
}

.list {
  > li:not(:last-child) {
    margin-bottom: 2px;
  }
}

.icon {
  min-width: 28px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background: var(--thm-grey-1);
  transition: 0.2s ease;
  color: var(--thm-silver-grey);

  svg {
    width: 20px;
    height: 20px;
  }
}

.list_link {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 4px;

  border-radius: 12px;
  transition: 0.2s ease;
  position: relative;

  &:hover:not(.list_link_active) {
    background: var(--thm-grey-1);

    .icon {
      color: var(--thm-black);
    }
  }

  &_active {
    background: var(--thm-grey-1);

    .icon {
      background: var(--clr-blue);
      color: var(--clr-white);
    }
  }
}

.count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;

  border-radius: 14px;
  background: var(--clr-primary);

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;

  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--clr-white);
}

.count_min {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--clr-primary);
  border: 1px solid var(--thm-white);

  position: absolute;
  top: 4px;
  right: 4px;
}
